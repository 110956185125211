import React, { useState } from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent, VerticalLayout } from '@altech-rdc/components/lib/layout'
import {
  TextContainer,
  HeroText,
  ContactRow
} from '@client/views/Partners/Partners'
import { PrimaryButton } from '@altech-rdc/components/lib/buttons'
import { PhoneIcon, EmailIcon } from '@altech-rdc/components/lib/icons'
import {
  TextArea,
  TextInput,
  FieldLabel,
  ErrorText
} from '@altech-rdc/components/lib/forms'
import { useForm } from 'react-hook-form'
import axios, { AxiosError } from 'axios'

interface ContactFormProps {
  theme: ITheme
}

const PhoneNumber = styled.span`
${({ theme }) => theme.fonts.bodyStyle3}
  color: ${({ theme }) => theme.colors.secondary};
`

const SpacedPhoneIcon = styled(PhoneIcon)`
  margin-right: 20px;
`

const ContactFormRow = styled(ContactRow)`
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 80px;
    margin-left: 40px;
    align-items: flex-start;
  }
`

const PhoneRow = styled(ContactRow)`
  margin-bottom: 0px;
  margin-top: 60px;
  margin-left: 0px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 80px;
    margin-left: 40px;
    align-items: flex-start;
  }
`

const LastContactFormRow = styled(ContactRow)`
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 0px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin-left: 40px;
    align-items: flex-start;
  }
`

const ContactInformation = styled.div`
  width: 65%;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin-left: -20px;
  }
`

const ContactText = styled.div`
  position: relative;
  left: 50px;
  top: -50px;
`

const ContactFormFields = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Form = styled.form`
  width: 75%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 20px;
`

const FormField = styled(VerticalLayout)`
  margin-bottom: 20px;
`

const ContactFormContainer = styled.div`
  margin-top: 90px;
  margin-bottom: 90px;
`

const StyledLink = styled.a`
  ${({ theme }) => theme.fonts.bodyStyle3}
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0;
  height: auto;
  text-decoration: none;
  & div {
    padding: 0;
  }
  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.focus};
    text-decoration: underline;
  }

  &:active {
    outline: 0;
    opacity: 1.0 !important;
    color: ${({ theme }) => theme.colors.focus};
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.disabled};
    background-color: transparent;
  }
`

const SpacedEmailIcon = styled(EmailIcon)`
  position: relative;
  top: -15px;
`

const ContactFormView: React.FC<ContactFormProps & IntlShapeProps> = props => {
  const { register, handleSubmit, reset, errors } = useForm()

  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState('')

  const submitFailure = (error: AxiosError) => {
    // eslint-disable-next-line
    console.log(JSON.stringify(error))
    setSubmitError(
      "We're sorry.  Your message failed to send.  Please try again."
    )
  }

  const onSubmit = (data: any, e: any) => {
    e.target.reset()
    axios({
      method: 'POST',
      withCredentials: false,
      url: `${window.config.EMAIL_URL}/send`,
      data
    }).then((response: any) => {
      if (response.data.status === 'success') {
        setSuccess(true)
      } else if (response.data.status === 'fail') {
        // eslint-disable-next-line
        setSubmitError(
          "We're sorry.  Your message failed to send.  Please try again."
        )
      }
    }, submitFailure)
  }
  return (
    <>
      <PageContent>
        <TextContainer>
          <HeroText>
            We partner with organisations across the globe who share our
            commitment to expanding access to clean, reliable, renewable and
            affordable energy across the DRC
          </HeroText>
        </TextContainer>
        <ContactInformation>
          <PhoneRow>
            <div>
              <SpacedPhoneIcon />
              <ContactText>
                Speak to or send a WhatsApp message to an Altech representative
                on
                <PhoneNumber> +243-822-230-188</PhoneNumber> during normal
                business hours
              </ContactText>
            </div>
          </PhoneRow>
          <PhoneRow>
            <div>
              <SpacedEmailIcon />
              <ContactText>
                Send us an email at&nbsp;
                <StyledLink
                  href="mailto:contact@altech-rdc.com"
                  target="_blank"
                >
                  contact@altech-rdc.com
                </StyledLink>
              </ContactText>
            </div>
          </PhoneRow>
          <LastContactFormRow>
            Or use the form below and we’ll get back to you as soon as we can.
          </LastContactFormRow>
        </ContactInformation>
        <ContactFormContainer>
          {!success ? (
            <ContactFormFields>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormField>
                  <FieldLabel>Name</FieldLabel>
                  <TextInput
                    type="text"
                    name="name"
                    ref={register({ required: true })}
                  />
                  {errors.name && <ErrorText>This field is required</ErrorText>}
                </FormField>
                <FormField>
                  <FieldLabel>Email</FieldLabel>
                  <TextInput
                    type="text"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address.'
                      }
                    })}
                  />
                  {errors.email && (
                    <ErrorText>
                      {errors.email.message} This field is required
                    </ErrorText>
                  )}
                </FormField>
                <FormField>
                  <FieldLabel>Message</FieldLabel>
                  <TextArea name="message" ref={register({ required: true })} />
                  {errors.message && (
                    <ErrorText>This field is required</ErrorText>
                  )}
                </FormField>

                {submitError && (
                  <FormField>
                    {' '}
                    <ErrorText>{submitError}</ErrorText>
                  </FormField>
                )}
                <SubmitButton type="submit">SUBMIT</SubmitButton>
              </Form>
            </ContactFormFields>
          ) : (
            <ContactFormFields>
              <ContactFormRow>
                Thank you very much. A member of our team will get back to you
                very soon.
              </ContactFormRow>
            </ContactFormFields>
          )}
        </ContactFormContainer>
      </PageContent>
    </>
  )
}

export const ContactForm = injectIntl(withTheme(ContactFormView))
