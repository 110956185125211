"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.SolarLamps = function (props) {
  return React.createElement("svg", Object.assign({
    width: 39,
    height: 40,
    viewBox: "0 0 39 40",
    fill: "none"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.5 3.85185C20.577 3.85185 21.45 2.98959 21.45 1.92593C21.45 0.862266 20.577 0 19.5 0C18.423 0 17.55 0.862266 17.55 1.92593C17.55 2.98959 18.423 3.85185 19.5 3.85185ZM21.037 28.8889C21.0125 29.1325 21 29.3796 21 29.6296C21 33.7206 24.3579 37.037 28.5 37.037V40H10.5V37.037C14.6421 37.037 18 33.7206 18 29.6296C18 29.3796 17.9875 29.1325 17.963 28.8889H10.7792C8.92653 26.8445 7.8 24.1448 7.8 21.1852C7.8 14.8032 13.0383 9.62963 19.5 9.62963C25.9617 9.62963 31.2 14.8032 31.2 21.1852C31.2 24.1448 30.0735 26.8445 28.2208 28.8889H21.037ZM8.46913 5.64091C9.23066 6.39303 9.23066 7.61246 8.46913 8.36458C7.70761 9.1167 6.47294 9.1167 5.71142 8.36458C4.9499 7.61246 4.9499 6.39303 5.71142 5.64091C6.47294 4.88879 7.70761 4.88879 8.46913 5.64091ZM30.5309 8.36458C31.2924 9.1167 32.5271 9.1167 33.2886 8.36458C34.0501 7.61246 34.0501 6.39303 33.2886 5.64091C32.5271 4.88879 31.2924 4.88879 30.5309 5.64091C29.7693 6.39303 29.7693 7.61246 30.5309 8.36458ZM37.05 21.1852C35.973 21.1852 35.1 20.3229 35.1 19.2593C35.1 18.1956 35.973 17.3333 37.05 17.3333C38.127 17.3333 39 18.1956 39 19.2593C39 20.3229 38.127 21.1852 37.05 21.1852ZM0 19.2593C0 20.3229 0.873045 21.1852 1.95 21.1852C3.02696 21.1852 3.9 20.3229 3.9 19.2593C3.9 18.1956 3.02696 17.3333 1.95 17.3333C0.873045 17.3333 0 18.1956 0 19.2593Z",
    fill: props.color || '#A2C0C5'
  }));
};