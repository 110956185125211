import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { YellowPath } from './YellowPath'

interface HeroProps {
  theme: ITheme
}

const HeroContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: flex-end;
  background: linear-gradient(
    359.43deg,
    rgba(0, 0, 0, 0.5) 0.85%,
    rgba(0, 0, 0, 0) 99.02%
  );
  padding-right: 0px;
`

const PathContainer = styled.div`
  position: absolute;
  top: -500px;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  margin-right: 0px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: -420px;
  }
`

const HeroTextContainer = styled.div`
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    top: 70px;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.sm}px) {
    top: 20px;
  }
`

const H1Text = styled.div`
  ${({ theme }) => theme.fonts.heroStyle};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    ${({ theme }) => theme.fonts.h3Style};
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    line-height: 50px;
    padding-bottom: 10px;
    margin-left: 5%;
  }
`

const Accent = styled(YellowPath)`
  margin-top: 24px;
  margin-bottom: 92px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-left: 5%;
  }
`

class HeroView extends React.Component<HeroProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <HeroContainer>
        <PathContainer>
          <HeroTextContainer>
            {' '}
            <H1Text>
              Clean, affordable
              <br /> energy for DR Congo
            </H1Text>
            <Accent type="accent" />
          </HeroTextContainer>
        </PathContainer>
      </HeroContainer>
    )
  }
}

export const Hero = injectIntl(withTheme(HeroView))
