import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import {
  VerticalLayout,
  HorizontalLayout
} from '@altech-rdc/components/lib/layout'
import {
  Products,
  Distribution,
  Payments
} from '@altech-rdc/components/lib/icons'
import map from '@client/images/map.png'
import { NewsTitle } from './News'

interface AltechGroupProps {
  theme: ITheme
}
const TitleContainer = styled.div`
  width: 50%;
  margin-bottom: 50px;
  margin-top: 140px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 60px;
    margin-top: 40px;
  }
`
const PageTitle = styled.div`
  ${({ theme }) => theme.fonts.h3Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
`

const PageDescription = styled.div`
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.darkestCopy};
`

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin: 3% 0;
  padding-right: 50px;
`

const Title = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.darkCopy};
`

const Image = styled.img`
  margin-top: 50px;
  width: 504px;
  height: 504px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding-left: 5%;
  }
`

const BannerTitle = styled(NewsTitle)`
  padding-top: 30px;
  width: 60%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    padding-right: 10%;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 60px;
  }
`

const Kpis = styled(VerticalLayout)`
  padding-left: 70px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding-left: 0px;
    margin: 0 auto;
  }
`

const Kpi = styled(VerticalLayout)`
  padding-bottom: 30px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding-left: 0px;
  }
`

const StatementLayout = styled(HorizontalLayout)`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`

class AltechGroupView extends React.Component<
  AltechGroupProps & IntlShapeProps
> {
  render() {
    const { intl } = this.props
    return (
      <VerticalLayout>
        <TitleContainer>
          <PageTitle>We are Altech Group</PageTitle>
          <PageDescription>
            We’re the DRC market leader in the distribution of world-class clean
            energy products and services.
          </PageDescription>
        </TitleContainer>
        <StatementLayout>
          <Column>
            <div>
              <Products />
            </div>
            <Title>Life-Improving Products</Title>
            <Description>
              We identify and distribute the products most needed by Congolese
              households
            </Description>
          </Column>
          <Column>
            <div>
              <Distribution />
            </div>
            <Title>Fast, Nation-Wide Distribution</Title>
            <Description>
              Our network of Solar Ambassadors provides fast and widespread
              access to our products and services
            </Description>
          </Column>
          <Column>
            <div>
              <Payments />
            </div>
            <Title>Innovative Payment Solutions</Title>
            <Description>
              With mobile payments, customers can buy products in affordable
              installments
            </Description>
          </Column>
        </StatementLayout>
        <VerticalLayout>
          <Image src={map} />
        </VerticalLayout>
        <VerticalLayout>
          <StatementLayout>
            <BannerTitle>
              We have 3,500+ Solar Ambassadors in our network, working across
              22+ provinces. Between them, they distribute 10,000+ products
              every month.
            </BannerTitle>
            <Kpis>
              <Kpi>
                <NewsTitle>300,000+</NewsTitle>
                <Description>Products Sold</Description>
              </Kpi>
              <Kpi>
                <NewsTitle>1,500,000+</NewsTitle>
                <Description>Lives Impacted</Description>
              </Kpi>
              <Kpi>
                <NewsTitle>4,000+</NewsTitle>
                <Description>Jobs Created</Description>
              </Kpi>
            </Kpis>
          </StatementLayout>
        </VerticalLayout>
      </VerticalLayout>
    )
  }
}

export const AltechGroup = injectIntl(withTheme(AltechGroupView))
