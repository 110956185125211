import React from 'react'
import styled from '@client/styledComponents'
import { StackContainer } from './StackContainer'
import { StackItemContainer } from './StackItemContiner'

const IconContainer = styled(StackContainer)`
  margin-top: 25px;
  cursor: pointer;
`

const FacebookIcon: React.FC = () => {
  return (
    <IconContainer
      onClick={() =>
        window.open('https://web.facebook.com/AltechGroup/', '_blank')
      }
    >
      <StackItemContainer>
        <svg
          width="20"
          height="40"
          viewBox="0 0 20 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.29553 7.7467C4.29553 8.75472 4.29553 13.2539 4.29553 13.2539H0V19.9882H4.29553V40H13.1195V19.9888H19.0408C19.0408 19.9888 19.5953 16.7597 19.8641 13.2291C19.0934 13.2291 13.1528 13.2291 13.1528 13.2291C13.1528 13.2291 13.1528 9.31134 13.1528 8.62463C13.1528 7.93644 14.1149 7.01074 15.0658 7.01074C16.0149 7.01074 18.0181 7.01074 19.8734 7.01074C19.8734 6.09386 19.8734 2.92584 19.8734 2.39527e-08C17.3966 2.39527e-08 14.5789 2.39527e-08 13.3368 2.39527e-08C4.07749 -0.000463976 4.29553 6.74045 4.29553 7.7467Z"
            fill="white"
          />
        </svg>
      </StackItemContainer>
    </IconContainer>
  )
}

export { FacebookIcon }
