"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var colors_1 = require("./colors");

var fonts_1 = require("./fonts");

var grid_1 = require("./grid");

exports.getTheme = function (language) {
  return {
    colors: colors_1.colors,
    gradients: colors_1.gradients,
    shadows: colors_1.shadows,
    fonts: fonts_1.fonts(language),
    grid: grid_1.grid
  };
};