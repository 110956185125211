import React from 'react'
import splashImage from '@client/images/splash-image.png'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { HorizontalLayout } from '@altech-rdc/components/lib/layout'
import { LinkButton } from '@altech-rdc/components/lib/buttons'
import {
  SolarHome,
  SolarLamps,
  CookStove
} from '@altech-rdc/components/lib/icons'

import solarHome from '@client/images/solar-home.png'
import solarLamp from '@client/images/new-solar-lamp.png'
import stove from '@client/images/stove.png'

interface ProductsHeroProps {
  theme: ITheme
  setElementToScrollTo: (element: string) => void
}

const ResponsiveHorizontalLayout = styled(HorizontalLayout)`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    flex-direction: column;
  }
`

const PathContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  margin-right: 0px;
  top: 200px;
`

const SolarLampIcon = styled(SolarLamps)`
  padding-right: 10px;
`
const CookStoveIcon = styled(CookStove)`
  padding-right: 10px;
`
const HeaderText = styled.div`
  padding-top: 5px;
  ${({ theme }) => theme.fonts.h5Style3};
  color: ${({ theme }) => theme.colors.white};
`
const SolarHomeIcon = styled(SolarHome)`
  padding-right: 10px;
`
const H5Text = styled.div`
  position: absolute;
  ${({ theme }) => theme.fonts.h5Style3};
  color: ${({ theme }) => theme.colors.white};
  top: 450px;
  left: 165px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    left: 20px;
    top: 440px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    width: 300px;
    left: 20px;
    top: 850px;
  }
`
const Rectangle = styled.section`
  background: ${({ theme }) => theme.colors.darkCopy};
  border-radius: 35.25px;
  margin-right: 15px;
  margin-bottom: 100px;
  margin-top: 5px;
  padding: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.fonts.regularFont};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    margin-bottom: 10px;
    margin-top: 5px;
    width: 250px;
  }
`
const HeroContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: 0px;
  width: 100%;
  height: 600px;
  background: ${({ theme }) => theme.colors.headerBackground};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    height: 1200px;
  }
`

const H2Text = styled.div`
  ${({ theme }) => theme.fonts.headerStyle};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.headerStyle};
  }
`

const HeroTextContainer = styled.div`
  margin-left: 165px;
  top: -200px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    width: 100%;
    top: -30px;
    margin-left: 20px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    width: 100%;
    top: -50px;
    margin-left: 20px;
  }
`

const H1Text = styled.div`
  ${({ theme }) => theme.fonts.h2Style};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    ${({ theme }) => theme.fonts.h2Style};
    position: absolute;
    width: 90%;
    left: 20px;
    top: -50px;
  }
`

const RectangleContainer = styled.div`
  position: relative;
  left: 165px;
  top: 15px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    left: 20px;
    top: -40px;
    width: 90%;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    left: 40px;
    top: 250px;
    width: 300px;
  }
`

class ProductsHeroView extends React.Component<
  ProductsHeroProps & IntlShapeProps
> {
  render() {
    const { intl, theme, setElementToScrollTo } = this.props
    return (
      <HeroContainer>
        <PathContainer>
          <HeroTextContainer>
            <H1Text>
              Affordable energy products, <br /> delivered direct to your door
            </H1Text>
          </HeroTextContainer>
          <RectangleContainer>
            <H2Text>Jump To:</H2Text>
            <ResponsiveHorizontalLayout>
              <Rectangle
                onClick={() => {
                  setElementToScrollTo('solarHomeSystems')
                }}
              >
                <HorizontalLayout>
                  <SolarHomeIcon color={theme.colors.primary}></SolarHomeIcon>
                  <HeaderText>Solar Home Systems</HeaderText>
                </HorizontalLayout>
              </Rectangle>

              <Rectangle
                onClick={() => {
                  setElementToScrollTo('solarLamps')
                }}
              >
                <HorizontalLayout>
                  <SolarLampIcon color={theme.colors.primary}></SolarLampIcon>

                  <HeaderText>Solar Lamps</HeaderText>
                </HorizontalLayout>
              </Rectangle>

              <Rectangle
                onClick={() => {
                  setElementToScrollTo('cookStoves')
                }}
              >
                <HorizontalLayout>
                  <CookStoveIcon color={theme.colors.primary}></CookStoveIcon>
                  <HeaderText>Clean Cookstoves</HeaderText>
                </HorizontalLayout>
              </Rectangle>
            </ResponsiveHorizontalLayout>
          </RectangleContainer>
        </PathContainer>
        <H5Text>
          We offer safe, clean, reliable and affordable <br /> energy solutions
          that help customers save money
          <br /> and improve their health, education <br /> and environmental
          outcomes.
        </H5Text>
      </HeroContainer>
    )
  }
}

export const ProductHero = injectIntl(withTheme(ProductsHeroView))
