"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.EmailIcon = function (props) {
  return React.createElement("svg", Object.assign({
    width: 30,
    height: 21,
    viewBox: "0 0 30 21",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M28.3569 0.00011808H1.644L15.0004 10.24L28.3569 0.00011808ZM0.000431544 1.26019V20.0001C0.000431544 20.5524 0.448148 21.0001 1.00043 21.0001H29.0004C29.5527 21.0001 30.0004 20.5524 30.0004 20.0001V1.26019L16.2173 11.8273C15.5443 12.3432 14.6251 12.3755 13.9213 11.924L13.7836 11.8273L0.000431544 1.26019Z",
    fill: "#A1B5B8"
  }));
};