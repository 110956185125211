"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.Quote = function (props) {
  return React.createElement("svg", Object.assign({
    width: 33,
    height: 24,
    viewBox: "0 0 33 24",
    fill: "none"
  }, props), React.createElement("path", {
    d: "M27.48 10.096L31.704 4.208C32.344 3.376 32.408 2.288 31.256 1.456L30.232 0.751999C28.888 -0.144001 27.416 0.239998 25.944 1.264C22.68 3.44 16.792 9.392 16.792 15.728C16.792 20.4 20.248 23.344 24.152 23.344C28.248 23.344 31.32 20.144 31.32 16.24C31.32 13.616 29.784 11.248 27.48 10.096ZM10.904 10.096L15.128 4.208C15.768 3.376 15.832 2.288 14.68 1.456L13.656 0.751999C12.312 -0.144001 10.84 0.239998 9.368 1.264C6.104 3.44 0.216 9.392 0.216 15.728C0.216 20.4 3.672 23.344 7.576 23.344C11.672 23.344 14.744 20.144 14.744 16.24C14.744 13.616 13.208 11.248 10.904 10.096Z",
    fill: "#1BACCD"
  }));
};