import { messages } from '@client/i18n/messages/views/home'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'
import { PageContent, Content } from '@altech-rdc/components/lib/layout'
import * as React from 'react'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { ProductHero } from '@client/components/products/ProductHero'
import { ProductsQuote } from '@client/components/products/ProductsQuote'
import { ProductsSection } from '@client/components/products/ProductsSection'
import { connect } from 'react-redux'
import { PromoBanner } from '@client/components/products/PromoBanner'
import { ProductContactForm } from '@client/components/products/ProductContactForm'

interface IBaseHomeProps {
  theme: ITheme
  language: string
}

type HomeProps = IBaseHomeProps & IntlShapeProps

interface IHomeState {
  width: number
  scrollRef: string | null
}

class ProductsHeroView extends React.Component<HomeProps, IHomeState> {
  constructor(props: HomeProps) {
    super(props)
    this.state = {
      width: window.innerWidth,
      scrollRef: null
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recordWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recordWindowWidth)
  }

  recordWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  setElementToScrollTo = (scrollRef: string) => {
    this.setState({ scrollRef })
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Content>
          <ProductHero setElementToScrollTo={this.setElementToScrollTo} />
          <ProductsQuote />
          <ProductsSection scrollRef={this.state.scrollRef} />
          <PromoBanner />
          <ProductContactForm />
          <PageContent></PageContent>
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    language: getLanguage(state)
  }
}

export const Products = connect(
  mapStateToProps,
  null
)(injectIntl(withTheme(ProductsHeroView)))
