import React from 'react'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'

import styled, { ITheme, withTheme } from '@client/styledComponents'
import {
  PageContent,
  HorizontalLayout,
  VerticalLayout
} from '@altech-rdc/components/lib/layout'
import { Partner } from '@client/components/Partner'

import laDifferenceLogo from '@client/images/la-difference-logo.png'
import persistentLogo from '@client/images/persistent-logo.png'
import omnivoltaicLogo from '@client/images/omnivoltaic-logo.png'
import burnstovesLogo from '@client/images/burnstoves-logo.png'
import swissReLogo from '@client/images/swissre.png'
import elanRdcLogo from '@client/images/elanrdc.png'
import solarCityLogo from '@client/images/solarcity.png'
import usAfricanLogo from '@client/images/usafrican.png'
import angazaLogo from '@client/images/Angaza.png'
import kivaLogo from '@client/images/Kiva.svg'
import ruralSparkLogo from '@client/images/RuralSpark.png'
import simaLogo from '@client/images/SIMA.png'
import uncdfLogo from '@client/images/UNCDF.svg'

import {
  ProductQuote,
  QuoteIcon,
  QuoteAuthor
} from '@client/components/home/Products'
import { Award } from '@client/components/Award'
import { EmailIcon } from '@altech-rdc/components/lib/icons/'

interface ProcessProps {
  theme: ITheme
}

export const TextContainer = styled.div`
  margin-top: 75px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;

    margin-top: 50px;
  }
`

const ResponsiveHorizontalLayout = styled(HorizontalLayout)`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-direction: column;
  }
`

export const HeroText = styled.div`
  padding: 5%;
  ${({ theme }) => theme.fonts.h2Style};
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.h5Style};
  }
`

const PartnersContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.partnersBackground};
`

const PartnersTitle = styled.div`
  margin-top: 90px;
  margin-bottom: 50px;
  ${({ theme }) => theme.fonts.h3Style2};

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-left: 25px;
    ${({ theme }) => theme.fonts.h5Style};
  }
`

const GridPartner = styled(Partner)`
  &:nth-child(even) {
    margin-left: 15px;
  }

  &:nth-child(odd) {
    margin-right: 15px;
  }

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    &:nth-child(even) {
      margin-left: 0;
    }

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  margin-bottom: 30px;
`

const QuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SpacedEmailIcon = styled(EmailIcon)`
  margin-right: ${({ theme }) => theme.grid.margin}px;
`

const Link = styled.a``

export const ContactRow = styled(HorizontalLayout)`
  padding-left: 5%;
  margin-left: 30px;
  align-items: center;
  ${({ theme }) => theme.fonts.bodyStyle};

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-direction: column;
    margin-left: 0;
    padding-left: 0;
  }
`

const ResponsiveProductQuote = styled.div`
  padding: 40px 0px 40px 75px;
  position: relative;

  ${({ theme }) => theme.fonts.h5Style3};
  color: ${({ theme }) => theme.colors.darkestCopy};

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.bodyStyle2};
    padding: 40px 15px 30px 65px;
  }
`

const PartnersView: React.FC<ProcessProps & IntlShapeProps> = props => {
  return (
    <>
      <PageContent>
        <TextContainer>
          <HeroText>
            We partner with organisations across the globe who share our
            commitment to expanding access to clean, reliable, renewable and
            affordable energy across the DRC
          </HeroText>
        </TextContainer>
      </PageContent>
      <PartnersContainer>
        <PageContent>
          <VerticalLayout>
            <PartnersTitle>Our Partners</PartnersTitle>
            <ResponsiveHorizontalLayout>
              <GridPartner
                title="UNCDF"
                url="https://www.uncdf.org/"
                logo={uncdfLogo}
              />
              <GridPartner
                title="SIMA"
                url="https://simafunds.com/"
                logo={simaLogo}
              />
            </ResponsiveHorizontalLayout>
            <ResponsiveHorizontalLayout>
              <GridPartner
                title="Angaza"
                url="https://www.angaza.com/"
                logo={angazaLogo}
              />
              <GridPartner
                title="Kiva"
                url="https://www.kiva.org/"
                logo={kivaLogo}
              />
            </ResponsiveHorizontalLayout>
            <ResponsiveHorizontalLayout>
              <GridPartner
                title="La Différence"
                url="https://www.la-difference.com/"
                logo={laDifferenceLogo}
              />
              <GridPartner
                title="Persistent"
                url="https://persistent.energy/"
                logo={persistentLogo}
              />
            </ResponsiveHorizontalLayout>
            <ResponsiveHorizontalLayout>
              <GridPartner
                title="Omnivoltaic"
                url="https://news.omnivoltaic.com/"
                logo={omnivoltaicLogo}
              />
              <GridPartner
                title="Burn Manufacturing"
                url="https://burnstoves.com"
                logo={burnstovesLogo}
              />
            </ResponsiveHorizontalLayout>
            <ResponsiveHorizontalLayout>
              <GridPartner
                title="RuralSpark"
                url="https://www.ruralspark.com/"
                logo={ruralSparkLogo}
                single={true}
              />
            </ResponsiveHorizontalLayout>
          </VerticalLayout>
        </PageContent>
      </PartnersContainer>
      <PageContent>
        <QuotesContainer>
          <ResponsiveProductQuote>
            <QuoteIcon />
            Washikala and Iongwa understand the realities of daily life in Congo
            and provide energy solutions they know will work. They are
            articulate, inventive and confident, and they are leading a new
            generation of social entrepreneurs in the country.
            <QuoteAuthor>
              Mike Beeston, Director and Co-Founder, La Différence
            </QuoteAuthor>
          </ResponsiveProductQuote>
          <ResponsiveProductQuote>
            <QuoteIcon />
            70 million people living in the DRC do not have access to safe,
            reliable energy. Altech has made greater inroads into reaching this
            population than any other company.
            <QuoteAuthor>
              Chris Aidun, Partner and Co-Founder, Persistent
            </QuoteAuthor>
          </ResponsiveProductQuote>
        </QuotesContainer>
        <TextContainer>
          <HeroText>
            We have been widely recognised for our pioneering work in the DRC
            energy access market, and are the proud recipient of the following
            awards
          </HeroText>
        </TextContainer>
      </PageContent>
      <PageContent>
        <VerticalLayout>
          <Award
            logo={swissReLogo}
            title="Swiss Re Foundation"
            subTitle="2018 Entrepreneurs for Resilience Award"
            urlText="swissrefoundation.org"
            url="https://swissrefoundation.org"
          />
          <Award
            logo={elanRdcLogo}
            title="ELAN RDC"
            subTitle="2017 Energy Access Challenge Award"
            urlText="elanrdc.com"
            url="https://elanrdc.com"
          />
          <Award
            logo={usAfricanLogo}
            title="US African Development Foundation"
            subTitle="2015 Business and Entrepreneurship Award"
            urlText="usadf.gov"
            url="https://usadf.gov"
          />
          <Award
            logo={solarCityLogo}
            title="SolarCity"
            subTitle="2015 GivePower Award"
            urlText="givepower.org"
            url="https://givepower.org"
          />
        </VerticalLayout>
        <TextContainer>
          <HeroText>Interested in working with Altech?</HeroText>
        </TextContainer>
        <ContactRow>
          <SpacedEmailIcon />
          Send us an email at&nbsp;
          <Link href="mailto:contact@altech-rdc.com">
            contact@altech-rdc.com
          </Link>
        </ContactRow>
      </PageContent>
    </>
  )
}

export const Partners = injectIntl(withTheme(PartnersView))
