import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { VerticalLayout } from '@altech-rdc/components/lib/layout'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import {
  Quote,
  SolarHome,
  SolarLamps,
  CookStove
} from '@altech-rdc/components/lib/icons'
import solarHome from '@client/images/solar-home.png'
import solarLamp from '@client/images/new-solar-lamp.png'
import stove from '@client/images/stove.png'
import Voltaic from '@client/images/Voltaic.png'

interface ProductsSectionProps {
  theme: ITheme
  scrollRef: string | null
}

const Rectangle = styled.div`
  background: ${({ theme }) => theme.colors.darkCopy};
  border-radius: 35.25px;
  color: ${({ theme }) => theme.colors.white};
`
const ProductsRow = styled.div`
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;
  justify-content: center;
`

const ProductsColumn = styled.div`
  width: 400px;
  flex-basis: 30%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-basis: 100%;
  }
  -ms-flex: auto;
  position: relative;
  padding: 40px 0px 40px 0px;
  box-sizing: border-box;
`

export const ProductQuote = styled.div`
  width: 60%;
  position: relative;
  padding: 40px 0px 40px 76px;
  ${({ theme }) => theme.fonts.h5Style3};
  color: ${({ theme }) => theme.colors.darkestCopy};
`

export const QuoteAuthor = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme.fonts.bodyStyle};
  color: ${({ theme }) => theme.colors.darkCopy};
`

const ProductTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
`

const ProductIconTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    margin-left: -40px;
  }
`

const ProductDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkCopy};
`

const ProductBackground = styled.div`
  ${({ theme }) => theme.gradients.gradientGrey};
  border-radius: 20px;
  width: 503px;
  height: 300px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 95%;
    margin: 0 auto;
    height: auto;
    padding: 0 20px 20px 20px;
  }
  text-align: center;
`

const ProductImage = styled.img`
  padding-top: 31px;
  max-width: 100%;
  height: auto;
`

export const QuoteIcon = styled(Quote)`
  position: absolute;
  top: 60px;
  left: 5px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 60px;
    left: 20px;
  }
`

const SolarHomeIcon = styled(SolarHome)`
  position: relative;
  top: 40px;
  left: 50px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    top: 60px;
    left: 20px;
  }
`

const SolarLampIcon = styled(SolarLamps)`
  position: relative;
  top: 40px;
  left: 50px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    top: 60px;
    left: 20px;
  }
`

const CookStoveIcon = styled(CookStove)`
  position: relative;
  top: 40px;
  left: 50px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    top: 60px;
    left: 20px;
  }
`

const ProductCopy = styled(VerticalLayout)`
  padding: 0px 0px 0px 75px;
  margin-left: 50px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding: 20px 75px 20px 75px;
  }
`

const ProductsItems = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 200px;
  }
`

class ProductsSectionView extends React.Component<
  ProductsSectionProps & IntlShapeProps
> {
  private solarLamps = React.createRef<HTMLDivElement>()
  private solarHomeSystems = React.createRef<HTMLDivElement>()
  private cookStoves = React.createRef<HTMLDivElement>()
  scrollTo(element: HTMLDivElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }
  render() {
    const { intl, scrollRef } = this.props

    let scrollTarget
    if (scrollRef === 'solarLamps') {
      scrollTarget = this.solarLamps
    } else if (scrollRef === 'solarHomeSystems') {
      scrollTarget = this.solarHomeSystems
    } else if (scrollRef === 'cookStoves') {
      scrollTarget = this.cookStoves
    }
    if (scrollTarget && scrollTarget.current) {
      this.scrollTo(scrollTarget.current)
    }
    return (
      <ProductsItems>
        <ProductsRow>
          <ProductsColumn>
            <SolarHomeIcon />
            <ProductCopy>
              <ProductIconTitle>
                <div ref={this.solarHomeSystems}>Solar Home Systems</div>
              </ProductIconTitle>
            </ProductCopy>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <ProductCopy>
              <ProductTitle>OvCamp</ProductTitle>
              <ProductDescription>
                Available with a 24”, 32” or 43” TV, the ovCamp Solar Home
                System can power a wide range of appliances, from LED lights to
                radios, TVs and phone charging stations.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={solarHome} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <SolarLampIcon />
            <ProductCopy>
              <ProductIconTitle>
                <div ref={this.solarLamps}>Solar Lamps</div>
              </ProductIconTitle>
            </ProductCopy>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <ProductCopy>
              <ProductTitle>Omnivoltaic L100 &amp; L190</ProductTitle>
              <ProductDescription>
                This portable solar light includes a solar power charging panel
                and offers 12 hours of light from a single day’s charging. It
                includes a mobile phone charging port and a touch switch.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={solarLamp} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductQuote>
            <QuoteIcon />
            Without this lantern I can’t sell at night... would like to advise
            all people who need lamps to buy this lantern.
            <QuoteAuthor>Blaise, Street vendor, Bukavu</QuoteAuthor>
          </ProductQuote>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <ProductCopy>
              <ProductTitle>Omnivoltaic M600</ProductTitle>
              <ProductDescription>
                This multilight solar system consists of a main light and 3
                secondary lights, is 65 times brighter than a kerosene lamp and
                offers 6.3 hours of light from a single days charging.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={Voltaic} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <CookStoveIcon />
            <ProductCopy>
              <ProductIconTitle>
                <div ref={this.cookStoves}>Clean Cookstoves</div>
              </ProductIconTitle>
            </ProductCopy>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <ProductCopy>
              <ProductTitle>Jikokoa Xtra</ProductTitle>
              <ProductDescription>
                The Jikokoa is a safer, cleaner and a more affordable
                alternative to clay stoves. With a larger cooking surface that
                can accommodate big pots, the Jikokoa Xtra is the most durable
                charcoal stove on the market. Switching to the Jikokoa Xtra
                stove could help you save an estimated $120-144 per year, whilst
                reducing exposure to harmful cooking smoke.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={stove} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
      </ProductsItems>
    )
  }
}

export const ProductsSection = injectIntl(withTheme(ProductsSectionView))
