import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent, VerticalLayout } from '@altech-rdc/components/lib/layout'
import { LinkButton } from '@altech-rdc/components/lib/buttons'
import { LinkArrow } from '@altech-rdc/components/lib/icons'
import Sombabien from '@client/images/Sombabien.png'

interface PromoBannerProps {
  theme: ITheme
}

const Wrapper = styled.div`
  /* stylelint-disable-next-line color-no-hex */
  background: linear-gradient(102.65deg, #81a85e 0%, #357938 99.52%);
  width: 100%;
  height: 559px;
  margin-top: 90px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 700px;
  }
`

const PromoTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
`

const PromoDesc = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 30px;
`

const ComingSoon = styled.div`
  color: ${({ theme }) => theme.colors.white};
`

const PromoCopy = styled(VerticalLayout)`
  margin-top: 150px;
  width: 400px;
`

const PromoLink = styled(LinkButton)`
  vertical-align: middle;
  width: 150px;
  color: ${({ theme }) => theme.colors.white};
`

const PromoRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 95%;
    margin: 0 auto;
  }
`

const PromoImageCont = styled.div`
  width: 658px;
  height: 559px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    top: 141px;
  }
  @media (max-width: 853px) and (min-width: 693px) {
    position: relative;
    top: 360px;
    width: 400px;
  }
  @media (max-width: 692px) and (min-width: 473px) {
    position: relative;
    top: 445px;
    width: 300px;
  }
  @media (max-width: 472px) and (min-width: 200px) {
    position: relative;
    top: 63px;
    width: 300px;
  }
`

const PromoImage = styled.img`
  max-width: 100%;
  height: auto;
`

class PromoBannerWrapper extends React.Component<
  PromoBannerProps & IntlShapeProps
> {
  render() {
    const { intl } = this.props
    return (
      <Wrapper>
        <PageContent>
          <PromoRow>
            <PromoCopy>
              <ComingSoon>COMING SOON</ComingSoon>
              <PromoTitle>Sombabien</PromoTitle>
              <PromoDesc>
                Our new app lets you order products straight to your door, and
                pay for them in installments.
              </PromoDesc>
              <PromoLink
                onClick={() =>
                  window.open(
                    'https://www.la-difference.com/case-study-sombabien',
                    '_blank'
                  )
                }
                icon={() => <LinkArrow color="white" />}
              >
                Find out more
              </PromoLink>
            </PromoCopy>
            <PromoImageCont>
              <PromoImage src={Sombabien} />
            </PromoImageCont>
          </PromoRow>
        </PageContent>
      </Wrapper>
    )
  }
}

export const PromoBanner = injectIntl(withTheme(PromoBannerWrapper))
