"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var colorDictionary = {
  white: '#FFFFFF',
  black: '#000000',
  purpleDark: '#2B0C7C',
  purpleLight: '#4514A3',
  redDark: '#C33658',
  redLight: '#E75167',
  greenDark: '#40A64E',
  greenLight: '#65C67A',
  blueDark: '#158FBD',
  blueLight: '#1BACCD',
  yellow: '#FEEB34',
  barGrey: '#A0B5B8',
  greyBlack: '#010709',
  greyDark: '#434B4D',
  greyMid: '#7D8284',
  greyLight: '#A9AEB0',
  opaqueGreyLight: '#A9AEB07F',
  greyLightest: '#E1E4E5',
  greyMidLight: '#F0F2F3',
  blueGreyMid: '#A2C0C5',
  blueGreyLightest: '#E4EDEE',
  opaqueGrey: '#35495d00'
};
exports.gradients = {
  gradientPurple: 'background: linear-gradient(180deg, #2B0C7C 0%, #4514A3 100%)',
  gradientBlue: 'background: linear-gradient(180deg, #158FBD 0%, #1BACCD 100%)',
  gradientGreen: 'background: linear-gradient(180deg, #40A64E 0%, #65C67A 100%)',
  gradientGrey: 'background: linear-gradient(360deg, #F5F6F6 0%, #E4EBED 100%);'
};
exports.shadows = {
  mistyShadow: 'box-shadow: 0px 2px 6px rgba(53, 67, 93, 0.32)',
  thickShadow: 'box-shadow: 0px 2px 8px rgba(53, 67, 93, 0.54)'
};
exports.colors = {
  primary: colorDictionary.yellow,
  secondary: colorDictionary.blueLight,
  focus: colorDictionary.blueDark,
  copy: colorDictionary.black,
  darkestCopy: colorDictionary.greyBlack,
  darkCopy: colorDictionary.greyDark,
  lightCopy: colorDictionary.greyLight,
  formBorder: colorDictionary.opaqueGreyLight,
  disabled: colorDictionary.greyLight,
  background: colorDictionary.white,
  greyBackground: colorDictionary.greyLightest,
  headerBackground: colorDictionary.greyBlack,
  footerBackground: colorDictionary.greyBlack,
  newsBackground: colorDictionary.blueGreyLightest,
  partnersBackground: colorDictionary.greyMidLight,
  mobileMenuBackground: colorDictionary.opaqueGrey,
  barColor: colorDictionary.barGrey,
  white: colorDictionary.white,
  black: colorDictionary.black,
  error: colorDictionary.redDark
};