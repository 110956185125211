import React from 'react'
import process1 from '@client/images/process1.png'
import process2 from '@client/images/process2.png'
import process3 from '@client/images/process3.png'
import backSlash from '@client/images/background-backslash2.svg'
import forwardSlash from '@client/images/background-forwardslash2.svg'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import {
  PageContent,
  HorizontalLayout,
  VerticalLayout
} from '@altech-rdc/components/lib/layout'

interface ProcessProps {
  theme: ITheme
}

const TextContainer = styled.div`
  position: relative;
  width: 60%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const HeroText = styled.div`
  ${({ theme }) => theme.fonts.h2Style};
  margin-top: 60px;
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.h3Style2};
    margin-left: 5%;
  }
`

const Wrapper = styled.div`
  height: 1350px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 1200px;
  }
`

const ProcessStatementContainer = styled.div`
  height: 297px;
  margin-bottom: 30px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const ProcessStatement = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  width: 945px;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const ProcessStatementWrapper = styled(HorizontalLayout)`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-direction: column;
  }
`

const ProcessStatementTitle = styled.div`
  position: absolute;
  height: 297px;
  background-image: url(${forwardSlash});
  width: 474px;
  padding-left: 40px;
  padding-top: 40px;
  ${({ theme }) => theme.fonts.h5Style};
  z-index: 20;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-image: none;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    padding-left: 5%;
  }
`
const ProcessStatementTitle2 = styled.div`
  position: absolute;
  height: 297px;
  background-image: url(${backSlash});
  width: 474px;
  padding-left: 40px;
  padding-top: 40px;
  ${({ theme }) => theme.fonts.h5Style};
  z-index: 20;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-image: none;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    padding-left: 5%;
  }
`

const ProcessStatementImage1 = styled.div`
  position: absolute;
  background: url(${process1}) no-repeat center center scroll;
  width: 595px;
  height: 297px;
  left: 350px;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: none;
  }
`

const ProcessStatementDescription = styled.div`
  position: absolute;
  height: 297px;
  width: 350px;
  top: 90px;
  left: 40px;
  ${({ theme }) => theme.fonts.bodyStyle};
  z-index: 20;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    padding-right: 5%;
  }
`

const ProcessStatementImage2 = styled.div`
  position: absolute;
  background: url(${process2}) no-repeat center center scroll;
  width: 595px;
  height: 297px;
  left: 350px;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: none;
  }
`

const ProcessStatementImage3 = styled.div`
  position: absolute;
  background: url(${process3}) no-repeat center center scroll;
  width: 595px;
  height: 297px;
  left: 350px;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: none;
  }
`

class ProcessView extends React.Component<ProcessProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <Wrapper>
        <PageContent>
          <TextContainer>
            <HeroText>
              We use a three-pronged approach to expanding energy access:
            </HeroText>
          </TextContainer>
        </PageContent>
        <PageContent>
          <ProcessStatementContainer>
            <ProcessStatement>
              <ProcessStatementWrapper>
                <VerticalLayout>
                  <ProcessStatementTitle>
                    1. Living Energy Poverty
                  </ProcessStatementTitle>
                  <ProcessStatementDescription>
                    We are Congolese. Our team live and work in the communities
                    we serve. We design solutions of, by and for the Congolese
                    people because we ourselves have lived energy poverty.
                  </ProcessStatementDescription>
                </VerticalLayout>
                <ProcessStatementImage1 />
              </ProcessStatementWrapper>
            </ProcessStatement>
          </ProcessStatementContainer>
          <ProcessStatementContainer>
            <ProcessStatement>
              <ProcessStatementWrapper>
                <VerticalLayout>
                  <ProcessStatementTitle2>
                    2. The Right Tech For Communities
                  </ProcessStatementTitle2>
                  <ProcessStatementDescription>
                    We have developed a community pay-as-you-go (Pay-Go)
                    business model. Through a downpayment and successive
                    installments, customers can acquire their product over days,
                    weeks or months.
                  </ProcessStatementDescription>
                </VerticalLayout>
                <ProcessStatementImage2 />
              </ProcessStatementWrapper>
            </ProcessStatement>
          </ProcessStatementContainer>
          <ProcessStatementContainer>
            <ProcessStatement>
              <ProcessStatementWrapper>
                <VerticalLayout>
                  <ProcessStatementTitle>
                    3. Smarter Payments
                  </ProcessStatementTitle>
                  <ProcessStatementDescription>
                    We are Congolese. Our team live and work in the communities
                    we serve. We design solutions of, by and for the Congolese
                    people because we ourselves have lived energy poverty.
                  </ProcessStatementDescription>
                </VerticalLayout>
                <ProcessStatementImage3 />
              </ProcessStatementWrapper>
            </ProcessStatement>
          </ProcessStatementContainer>
        </PageContent>
      </Wrapper>
    )
  }
}

export const Process = injectIntl(withTheme(ProcessView))
