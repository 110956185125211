"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.Products = function (props) {
  return React.createElement("svg", Object.assign({
    width: 51,
    height: 32,
    viewBox: "0 0 51 32",
    fill: "none"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.2844 0.121047C13.9768 -0.045881 13.6042 -0.0398035 13.3022 0.137071L0.494996 7.63688L0.494675 7.63707C0.37733 7.70579 0.277384 7.79631 0.198693 7.90173C0.133603 7.98894 0.0830557 8.08634 0.0492343 8.19003C0.0170308 8.28877 -8.8314e-06 8.39322 3.43382e-09 8.5V23.9412C3.43382e-09 24.3114 0.204525 24.6513 0.53161 24.8247L13.8467 31.8835C13.9787 31.9535 14.1222 31.9919 14.2667 31.9988C14.2918 32 14.317 32.0003 14.3421 31.9996C14.4939 31.9956 14.6451 31.957 14.7837 31.8835L28.0988 24.8247C28.4259 24.6513 28.6304 24.3114 28.6304 23.9412V8.5C28.6304 8.14918 28.4465 7.82402 28.146 7.64315C28.1289 7.63287 28.1116 7.62314 28.094 7.61397L14.2844 0.121047ZM13.3151 16.1605L2 10.162V23.3395L13.3151 29.338V16.1605ZM22.0703 10.3157L25.5145 8.48977L13.8262 2.14788L10.5017 4.09472L22.0703 10.3157ZM19.2339 11.8193L14.3151 14.427L3.05307 8.45658L7.61959 5.78246L19.2339 11.8193ZM20.3069 13.5143V26.6918L15.3153 29.338V16.1605L20.3069 13.5143ZM22.97 25.28V12.1025L26.6304 10.162V23.3395L22.97 25.28ZM50.8491 5.3885V6.76341C50.835 6.79381 50.8242 6.82548 50.8167 6.85793C50.7445 7.40645 50.5805 7.94031 50.3307 8.43907C49.7804 9.52549 49.0431 10.516 48.1492 11.3693C46.3259 13.1807 44.2272 14.6502 41.9935 15.9564C41.9551 15.9846 41.9079 16 41.8594 16C41.8109 16 41.7637 15.9846 41.7253 15.9564C39.93 14.9326 38.2422 13.7458 36.6855 12.4126C35.5686 11.4732 34.5972 10.387 33.8003 9.1867C33.3619 8.52745 33.0667 7.79084 32.9321 7.0206C32.7977 6.25034 32.8267 5.46214 33.0173 4.70277C33.8345 1.23286 38.3594 -0.185017 41.1781 2.14029C41.3368 2.27025 41.4829 2.41434 41.6329 2.56227L41.633 2.56233C41.7111 2.63936 41.7903 2.71742 41.8729 2.79509C41.8877 2.77131 41.904 2.74836 41.9215 2.72634C43.1814 1.38065 44.7384 0.779135 46.6013 1.07817C48.6442 1.40472 49.9834 2.56995 50.635 4.44497C50.7244 4.75548 50.796 5.07047 50.8491 5.3885Z",
    fill: "#1BACCD"
  }));
};