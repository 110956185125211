import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { VerticalLayout } from '@altech-rdc/components/lib/layout'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { messages } from '@client/i18n/messages/views/news'
import { LinkButton } from '@altech-rdc/components/lib/buttons'
import { PRODUCTS } from '@client/navigation/routes'
import {
  LinkArrow,
  Quote,
  SolarHome,
  SolarLamps,
  CookStove
} from '@altech-rdc/components/lib/icons'
import * as navigationActions from '@client/navigation'
import solarHome from '@client/images/solar-home.png'
import solarLamp from '@client/images/new-solar-lamp.png'
import stove from '@client/images/stove.png'
import { connect } from 'react-redux'

interface ProductsProps {
  theme: ITheme
  goToPage: (route: string) => void
}

const ProductsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const ProductsColumn = styled.div`
  width: 400px;
  flex-basis: 50%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-basis: 100%;
  }
  -ms-flex: auto;
  position: relative;
  padding: 40px 0px 40px 0px;
  box-sizing: border-box;
`

export const ProductQuote = styled.div`
  width: 75%;
  position: relative;
  padding: 40px 0px 40px 76px;
  ${({ theme }) => theme.fonts.h5Style3};
  color: ${({ theme }) => theme.colors.darkestCopy};
`

export const QuoteAuthor = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme.fonts.bodyStyle};
  color: ${({ theme }) => theme.colors.darkCopy};
`

const ProductTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
`

const ProductDescription = styled.div`
  color: ${({ theme }) => theme.colors.darkCopy};
`

const ProductPageTitle = styled.div`
  ${({ theme }) => theme.fonts.h3Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
`

const ProductPageDescription = styled.div`
  padding-top: 20px;
  color: ${({ theme }) => theme.colors.darkestCopy};
`

const ProductsLinkContainer = styled.div`
  padding-top: 20px;
`

const ProductBackground = styled.div`
  ${({ theme }) => theme.gradients.gradientGrey};
  border-radius: 20px;
  width: 503px;
  height: 300px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 95%;
    margin: 0 auto;
    height: auto;
    padding: 0 20px 20px 20px;
  }
  text-align: center;
`

const ProductImage = styled.img`
  padding-top: 31px;
  max-width: 100%;
  height: auto;
`

export const QuoteIcon = styled(Quote)`
  position: absolute;
  top: 60px;
  left: 5px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 60px;
    left: 20px;
  }
`

const SolarHomeIcon = styled(SolarHome)`
  position: absolute;
  top: 120px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 60px;
    left: 20px;
  }
`

const SolarLampIcon = styled(SolarLamps)`
  position: absolute;
  top: 120px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 60px;
    left: 20px;
  }
`

const CookStoveIcon = styled(CookStove)`
  position: absolute;
  top: 120px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 60px;
    left: 20px;
  }
`

const ProductCopy = styled(VerticalLayout)`
  padding: 80px 75px 0px 75px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding: 20px 75px 20px 75px;
  }
`

const ProductsItems = styled.div`
  width: 100%;
  margin: 0 auto;
`

class ProductsView extends React.Component<ProductsProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <ProductsItems>
        <ProductsRow>
          <ProductsColumn>
            <ProductCopy>
              <ProductPageTitle>Our Products</ProductPageTitle>
              <ProductPageDescription>
                Our cost effective systems help off-grid customers power a wide
                range of appliances.
              </ProductPageDescription>
              <ProductsLinkContainer>
                <LinkButton
                  onClick={() => this.props.goToPage(PRODUCTS)}
                  icon={() => <LinkArrow color="#158FBD" />}
                >
                  Go to products page
                </LinkButton>
              </ProductsLinkContainer>
            </ProductCopy>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <SolarHomeIcon />
            <ProductCopy>
              <ProductTitle>Solar Home Systems</ProductTitle>
              <ProductDescription>
                Our cost effective systems help off-grid customers power a wide
                range of appliances.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={solarHome} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <SolarLampIcon />
            <ProductCopy>
              <ProductTitle>Solar Lamps</ProductTitle>
              <ProductDescription>
                Our world-class solar lamps are used in homes, schools,
                businesses and hospitals across DRC.
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={solarLamp} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductsColumn>
            <CookStoveIcon />
            <ProductCopy>
              <ProductTitle>Clean Cookstoves</ProductTitle>
              <ProductDescription>
                We distribute the Jikokoa Xtra Clean Cookstove, a safer, cleaner
                and more affordable alternative to clay stoves
              </ProductDescription>
            </ProductCopy>
          </ProductsColumn>
          <ProductsColumn>
            <ProductBackground>
              <ProductImage src={stove} />
            </ProductBackground>
          </ProductsColumn>
        </ProductsRow>
        <ProductsRow>
          <ProductQuote>
            <QuoteIcon />
            We are using the lantern to write our reports, register patients,
            and administer medicine at night.
            <QuoteAuthor>Solange, Nurse, Bukavu</QuoteAuthor>
          </ProductQuote>
        </ProductsRow>
      </ProductsItems>
    )
  }
}

const mapDispatchToProps = {
  goToPage: navigationActions.goToPage
}

export const Products = connect(
  null,
  mapDispatchToProps
)(injectIntl(withTheme(ProductsView)))
