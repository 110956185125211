import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent } from '@altech-rdc/components/lib/layout'
import { InverseYellowPath } from '@client/components/InverseYellowPath'
import {
  NewsItemCard,
  NewsItemContent,
  NewsTitle,
  Row,
  NewsLinkContainer,
  NewsLink
} from './News'
import { LinkArrow } from '@altech-rdc/components/lib/icons'
import livingConditions from '@client/images/living-conditions.png'
import { connect } from 'react-redux'
import { WHAT_WE_DO } from '@client/navigation/routes'
import * as navigationActions from '@client/navigation'

interface BannerProps {
  theme: ITheme
}

interface IDispatchProps {
  goToPage: (route: string) => void
}

const Container = styled.div`
  background: url(${livingConditions}) no-repeat;
  background-size: 100%;
  min-height: 760px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-size: cover;
    min-height: 500px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    background-size: cover;
    min-height: 500px;
  }
`

const NewsItemCardContainer = styled.div`
  position: relative;
  top: 50px;
  width: 683px;
  height: 412px;
  flex-basis: 50%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
    top: 5px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    width: 100%;
    top: 5px;
    height: auto;
    flex-basis: 100%;
  }
  -ms-flex: auto;
  padding: 10px;
  box-sizing: border-box;
`

const BannerTitle = styled(NewsTitle)`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    ${({ theme }) => theme.fonts.h5Style3};
  }
`

class BannerView extends React.Component<
  BannerProps & IntlShapeProps & IDispatchProps
> {
  render() {
    const { intl } = this.props
    return (
      <Container>
        <PageContent>
          <NewsItemCardContainer>
            <NewsItemCard>
              <NewsItemContent>
                <BannerTitle>
                  90% of Congolese people lack access to electricity.
                  <br />
                  <br />
                  We want to see energy poverty eliminated in the DRC by 2030.
                </BannerTitle>
              </NewsItemContent>
              <Row>
                <InverseYellowPath />
              </Row>
              <NewsLinkContainer>
                <NewsLink
                  onClick={() => this.props.goToPage(WHAT_WE_DO)}
                  icon={() => <LinkArrow color="black" />}
                >
                  Learn More About Our Mission
                </NewsLink>
              </NewsLinkContainer>
            </NewsItemCard>
          </NewsItemCardContainer>
        </PageContent>
      </Container>
    )
  }
}

const mapDispatchToProps = {
  goToPage: navigationActions.goToPage
}

export const Banner = connect(
  null,
  mapDispatchToProps
)(injectIntl(withTheme(BannerView)))
