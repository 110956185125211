import React from 'react'

const IconBackdrop: React.FC = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="90" height="90" rx="20" fill="white" />
    </svg>
  )
}

export { IconBackdrop }
