import React from 'react'
import styled from '@client/styledComponents'
import { StackContainer } from './StackContainer'
import { StackItemContainer } from './StackItemContiner'

const IconContainer = styled(StackContainer)`
  margin-top: 25px;
  padding-left: 30px;
  cursor: pointer;
`

const LinkedInIcon: React.FC = () => {
  return (
    <IconContainer
      onClick={() =>
        window.open('https://www.linkedin.com/company/18998434/', '_blank')
      }
    >
      <StackItemContainer>
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40 24.5239V40H31.4268V25.5604C31.4268 21.9331 30.1866 19.4576 27.0834 19.4576C24.7144 19.4576 23.3048 21.1259 22.6846 22.7397C22.4584 23.3167 22.4001 24.1197 22.4001 24.9272V40H13.824C13.824 40 13.9395 15.5442 13.824 13.0104H22.3993V16.8361C22.3819 16.8647 22.3593 16.8955 22.3429 16.9232H22.3993V16.8361C23.5386 14.9997 25.5733 12.3763 30.1276 12.3763C35.77 12.3762 40 16.2337 40 24.5239ZM4.85291 0C1.91901 0 0 2.01388 0 4.66223C0 7.25288 1.86355 9.32788 4.73914 9.32788H4.79639C7.78711 9.32788 9.64709 7.25327 9.64709 4.66223C9.59074 2.01388 7.78708 0 4.85291 0ZM0.509399 40H9.08228V13.0104H0.509399V40Z"
            fill="white"
          />
        </svg>
      </StackItemContainer>
    </IconContainer>
  )
}

export { LinkedInIcon }
