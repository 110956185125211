import { getTheme } from '@altech-rdc/components/lib/theme'
import ApolloClient from 'apollo-client'
import { History } from 'history'
import * as React from 'react'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router'
import styled, {
  ThemeProvider,
  createGlobalStyle
} from '@client/styledComponents'
import { I18nContainer } from '@client/i18n/components/I18nContainer'
import { AppStore } from '@client/store'
import * as routes from '@client/navigation/routes'
import { Page } from '@client/components/Page'
import { Header } from '@client/components/Header'
import { Home } from '@client/views/Home/Home'
import { Products } from '@client/views/Products/Products'
import { AboutUs } from '@client/views/AboutUs/AboutUs'
import { Partners } from '@client/views/Partners/Partners'
import { ConnectedRouter } from 'connected-react-router'
import { getDefaultLanguage } from '@client/i18n/utils'
import { Footer } from '@client/components/Footer'
import { WhatWeDo } from '@client/views/WhatWeDo/WhatWeDo'
import { ContactUs } from '@client/views/ContactUs/ContactUs'

interface IAppProps {
  client?: ApolloClient<{}>
  store: AppStore
  history: History
}
const MainSection = styled.section`
  flex-grow: 8;
  background: ${({ theme }) => theme.colors.background};
`

// Injecting global styles for the body tag - used only once
// eslint-disable-line
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width:100%;
    height:100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

export class App extends React.Component<IAppProps> {
  public render() {
    return (
      <>
        <GlobalStyle />
        <Provider store={this.props.store}>
          <I18nContainer>
            <ThemeProvider theme={getTheme(getDefaultLanguage())}>
              <ConnectedRouter history={this.props.history}>
                <Page>
                  <MainSection>
                    <Header />
                    <Switch>
                      <Route exact path={routes.HOME} component={Home} />
                      <Route
                        exact
                        path={routes.PRODUCTS}
                        component={Products}
                      />
                      <Route exact path={routes.ABOUT_US} component={AboutUs} />
                      <Route
                        exact
                        path={routes.WHAT_WE_DO}
                        component={WhatWeDo}
                      />
                      <Route
                        exact
                        path={routes.PARTNERS}
                        component={Partners}
                      />
                      <Route
                        exact
                        path={routes.CONTACT_US}
                        component={ContactUs}
                      />
                    </Switch>
                    <Footer />
                  </MainSection>
                </Page>
              </ConnectedRouter>
            </ThemeProvider>
          </I18nContainer>
        </Provider>
      </>
    )
  }
}
