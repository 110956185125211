import React from 'react'
import washikalaLongwa from '@client/images/washikala-longwa.png'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent } from '@altech-rdc/components/lib/layout'

interface AboutUsHeroProps {
  theme: ITheme
}

const AboutUsHeroContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: flex-end;
  background: linear-gradient(
    359.43deg,
    rgba(0, 0, 0, 0.5) 0.85%,
    rgba(0, 0, 0, 0) 99.02%
  );
  padding-right: 0px;
`

const SplashImage = styled.div`
  background: url(${washikalaLongwa}) no-repeat center center scroll;
  width: 100%;
  height: 700px;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 400px;
    width: 100%;
    background-size: cover;
    padding-right: 0px;
  }
`

const PathContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-right: 0;
  margin-right: 0px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 180px;
  }
`

const AboutUsHeroTextContainer = styled.div`
  position: relative;
  top: 320px;
  width: 861px;
  background: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const HeroText = styled.div`
  padding: 5%;
  ${({ theme }) => theme.fonts.h2Style};
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.h3Style2};
  }
`

const PageDescription = styled.div`
  width: 594px;
  margin: auto;
  position: relative;
  top: 300px;
  margin-bottom: 60px;
  color: ${({ theme }) => theme.colors.darkestCopy};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 720px) {
    margin-top: 200px;
  }
`

const Wrapper = styled.div`
  height: 1350px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 1200px;
  }
`

class AboutUsHeroView extends React.Component<
  AboutUsHeroProps & IntlShapeProps
> {
  render() {
    const { intl } = this.props
    return (
      <Wrapper>
        <AboutUsHeroContainer>
          <SplashImage />
          <PathContainer>
            <PageContent>
              <AboutUsHeroTextContainer>
                <HeroText>
                  Forced to flee their home village of Baraka in South Kivu
                  during the Congolese civil war, our co-founders Washikala
                  Malango and Iongwa Mashangao share a remarkable journey.
                </HeroText>
              </AboutUsHeroTextContainer>
            </PageContent>
          </PathContainer>
        </AboutUsHeroContainer>
        <PageContent>
          <PageDescription>
            They grew up together in a refugee camp in Tanzania where someone
            from the UNHCR spotted their talent and helped them earn
            scholarships to the University of Dar es Salaam.
            <br />
            <br />
            After finishing their degrees and earning the Washington Mandela
            Fellowship for Young African Leaders they returned to DRC with a
            plan. Driven by their vision to create impact at home they founded
            the Altech Group in 2013 to address DRC’s energy deficit.
          </PageDescription>
        </PageContent>
      </Wrapper>
    )
  }
}

export const AboutUsHero = injectIntl(withTheme(AboutUsHeroView))
