import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import {
  VerticalLayout,
  HorizontalLayout,
  PageContent
} from '@altech-rdc/components/lib/layout'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { messages } from '@client/i18n/messages/views/news'
import { InverseYellowPath } from '@client/components/InverseYellowPath'
import { LinkButton } from '@altech-rdc/components/lib/buttons'
import { LinkArrow } from '@altech-rdc/components/lib/icons'
import Slider from 'react-slick'
import './slick.css'
import { ArrowBackButton } from '@client/components/home/ArrowBackButton'
import { ArrowForwardButton } from '@client/components/home/ArrowForwardButton'

interface NewsProps {
  theme: ITheme
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.newsBackground};
  min-height: 850px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    min-height: 950px;
  }
`

const TitleWrapper = styled.div`
  position: relative;
  padding-top: 80px;
  top: 30px;
`

const NewsCarouselButtons = styled(HorizontalLayout)`
  max-width: 200px;
  position: relative;
  left: 820px;
  top: 10px;
  margin-top: -76px;
  margin-bottom: 85px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    left: 0px;
    top: 10px;
    margin-top: 53px;
    margin-left: 5px;
  }
`

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
`

const NewsItem = styled.div`
  width: 400px;
  height: 500px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
`

export const NewsItemContent = styled.div`
  height: 343px;
  padding: 8%;
  ${({ theme }) => theme.fonts.bodyStyle};
`

export const NewsItemCard = styled(VerticalLayout)`
  background: ${({ theme }) => theme.colors.white};
`

const Date = styled.div`
  padding-top: 5px;
  color: ${({ theme }) => theme.colors.lightCopy};
  padding-bottom: 20px;
`

const TextBold = styled.div`
  ${({ theme }) => theme.fonts.h2Style};
  padding-left: 1%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    padding-left: 5%;
  }
  padding-bottom: 50px;
`

export const NewsLinkContainer = styled.div`
  /* stylelint-disable */
  line-height: 100px;
  /* stylelint-enable */
  background: ${({ theme }) => theme.colors.primary};
  padding-left: 8%;
`

export const NewsLink = styled(LinkButton)`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.black};
`
export const NewsTitle = styled.div`
  ${({ theme }) => theme.fonts.h3Style2};
`

export interface IStateProps {
  slider: Slider
}

const ActionButton = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

class NewsView extends React.Component<NewsProps & IntlShapeProps> {
  private slider = React.createRef<Slider>()
  constructor(props: NewsProps & IntlShapeProps) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    const node = this.slider.current
    if (node) {
      node.slickNext()
    }
  }
  previous() {
    const node = this.slider.current
    if (node) {
      node.slickPrev()
    }
  }
  render() {
    const { intl } = this.props
    let numberOfSlides = 2
    if (window.innerWidth <= 1014) {
      numberOfSlides = 1
    }
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: numberOfSlides,
      slidesToScroll: 1
    }
    return (
      <Container>
        <PageContent>
          <TitleWrapper>
            <TextBold>{intl.formatMessage(messages.newsTitle)}</TextBold>
          </TitleWrapper>
          <NewsCarouselButtons>
            <ActionButton onClick={this.previous}>
              <ArrowBackButton />
            </ActionButton>
            <ActionButton onClick={this.next}>
              <ArrowForwardButton />
            </ActionButton>
          </NewsCarouselButtons>
          <Slider ref={this.slider} {...settings}>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Quarterly Company Update: Q1 2021</NewsTitle>
                  <Date>May 20th 2021</Date>A review of our work over the first
                  quarter of 2021.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Quarterly_Update_2021_Q1.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Press release</NewsTitle>
                  <Date>January 13th 2021</Date>Altech closes $500,000 of
                  financing from UNCDF
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Press-Release-Altech-UNCDF_2021-01-13.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Quarterly Company Update: Q4 2020</NewsTitle>
                  <Date>February 11th 2021</Date>A review of our work over the
                  fourth quarter of 2020.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Quarterly-Update-2020-Q4.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Quarterly Company Update: Q3 2020</NewsTitle>
                  <Date>November 6th 2020</Date>A review of our work over the
                  third quarter of 2020.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Quarterly_Update_2020_Q3.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>

            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Altech secures $500,000 loan</NewsTitle>
                  <Date>October 16th 2020</Date>Altech secures $500,000 in
                  financing from SIMA Angaza Distributor Finance Fund
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/SIMA_Angaza-Altech_Press_Release.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Press release</NewsTitle>
                  <Date>September 19th 2020</Date>Altech &amp; Garamba National
                  Park provide access to energy to 6,500 households.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Altech-Garamba-release.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Quarterly Company Update: Q2 2020</NewsTitle>
                  <Date>July 14th 2020</Date>A review of our work over the
                  second quarter of 2020.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Quarterly_Update_2020_Q2.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Quarterly Company Update: Q1 2020</NewsTitle>
                  <Date>April 18th 2020</Date>A review of our work over the
                  first quarter of 2020.
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.altech-rdc.com/newsletters/Quarterly_Update_2020_Q1.pdf',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    Read more ...
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>
                    How to pivot from a cash-based to a PAYGO model
                  </NewsTitle>
                  <Date>February 2nd 2020</Date>
                  An interview with our CEO, Washikala as part of a
                  collaboration between the Finding Impact Podcast and the
                  Global Distributors Collective (GDC)
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://findingimpact.com/fip-108-last-mile-distribution-3-3-how-to-pivot-from-a-cash-based-to-a-paygo-model-with-washikala-of-altech/',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    https://findingimpact.com
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
            <NewsItem>
              <NewsItemCard>
                <NewsItemContent>
                  <NewsTitle>Supporting entrepreneurship in the DRC</NewsTitle>
                  <Date>February 2nd 2020</Date>A video profile of Altech Group
                  and our solar lamp distribution system
                </NewsItemContent>
                <Row>
                  <InverseYellowPath />
                </Row>
                <NewsLinkContainer>
                  <NewsLink
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=RYfNbObvf2E',
                        '_blank'
                      )
                    }
                    icon={() => <LinkArrow color="black" />}
                  >
                    YouTube
                  </NewsLink>
                </NewsLinkContainer>
              </NewsItemCard>
            </NewsItem>
          </Slider>
        </PageContent>
      </Container>
    )
  }
}

export const News = injectIntl(withTheme(NewsView))
