import { messages } from '@client/i18n/messages/views/home'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'
import { PageContent, Content } from '@altech-rdc/components/lib/layout'
import * as React from 'react'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { News } from '@client/components/home/News'
import { Banner } from '@client/components/home/Banner'
import { ProductHero } from '@client/components/products/ProductHero'
import { Products } from '@client/components/home/Products'
import { AltechGroup } from '@client/components/home/AltechGroup'
import { connect } from 'react-redux'
import { Hero } from '@client/components/Hero'
import { HeroImage } from '@client/components/home/HeroImage'

interface IBaseHomeProps {
  theme: ITheme
  language: string
}

type HomeProps = IBaseHomeProps & IntlShapeProps

interface IHomeState {
  width: number
}

class HomeView extends React.Component<HomeProps, IHomeState> {
  constructor(props: HomeProps) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recordWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recordWindowWidth)
  }

  recordWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Content>
          <HeroImage id="hero-image" />
          <PageContent>
            <Hero />
          </PageContent>
          <PageContent>
            <AltechGroup />
          </PageContent>
          <Banner />
          <PageContent>
            <Products />
          </PageContent>
          <News />
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    language: getLanguage(state)
  }
}

export const Home = connect(
  mapStateToProps,
  null
)(injectIntl(withTheme(HomeView)))
