import React from 'react'
import styled from '@client/styledComponents'
import { StackContainer } from './StackContainer'
import { StackItemContainer } from './StackItemContiner'

const IconContainer = styled(StackContainer)`
  margin-top: 25px;
  padding-left: 30px;
  cursor: pointer;
`

const TwitterIcon: React.FC = () => {
  return (
    <IconContainer
      onClick={() => window.open('https://twitter.com/GroupAltech', '_blank')}
    >
      <StackItemContainer>
        <svg
          width="40"
          height="32"
          viewBox="0 0 40 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M40 3.78743C38.5276 4.42975 36.9477 4.86534 35.2878 5.05976C36.9827 4.0606 38.2826 2.47574 38.8951 0.590633C37.3102 1.51596 35.5528 2.1878 33.6829 2.55203C32.1855 0.981927 30.0531 0 27.6933 0C23.1611 0 19.4863 3.61763 19.4863 8.0769C19.4863 8.70937 19.5588 9.32708 19.6988 9.91771C12.8792 9.58056 6.83207 6.36407 2.78483 1.47658C2.07737 2.67015 1.6749 4.05814 1.6749 5.53718C1.6749 8.34023 3.1223 10.8135 5.32467 12.2606C3.98225 12.2212 2.71733 11.8594 1.6099 11.254C1.6099 11.2885 1.6099 11.3205 1.6099 11.3549C1.6099 15.2679 4.43972 18.5336 8.19199 19.2768C7.50203 19.4614 6.77708 19.5598 6.02962 19.5598C5.49966 19.5598 4.98719 19.5081 4.48472 19.4146C5.52965 22.6237 8.55947 24.9592 12.1492 25.0256C9.34192 27.1913 5.80214 28.4833 1.95738 28.4833C1.29492 28.4833 0.64246 28.4439 0 28.3701C3.63477 30.6612 7.947 32 12.5817 32C27.6758 32 35.9303 19.6902 35.9303 9.01453C35.9303 8.66508 35.9228 8.31562 35.9053 7.96862C37.5077 6.83165 38.9001 5.40921 40 3.78743Z"
            fill="white"
          />
        </svg>
      </StackItemContainer>
    </IconContainer>
  )
}

export { TwitterIcon }
