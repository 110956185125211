import { messages } from '@client/i18n/messages/views/home'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'
import { PageContent, Content } from '@altech-rdc/components/lib/layout'
import * as React from 'react'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { connect } from 'react-redux'
import { ContactForm } from '@client/components/contactus/ContactForm'

interface IBaseContactUsProps {
  theme: ITheme
  language: string
}

type ContactUsProps = IBaseContactUsProps & IntlShapeProps

interface IContactUsState {
  width: number
}

class ContactUsView extends React.Component<ContactUsProps, IContactUsState> {
  constructor(props: ContactUsProps) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recordWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recordWindowWidth)
  }

  recordWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Content>
          <PageContent>
            <ContactForm />
          </PageContent>
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    language: getLanguage(state)
  }
}

export const ContactUs = connect(
  mapStateToProps,
  null
)(injectIntl(withTheme(ContactUsView)))
