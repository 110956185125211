import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent } from '@altech-rdc/components/lib/layout'
import livingConditions from '@client/images/living-conditions.png'
import {
  ProductQuote,
  QuoteAuthor,
  QuoteIcon
} from '@client/components/home/Products'

interface QuoteProps {
  theme: ITheme
}

const Container = styled.div`
  background: url(${livingConditions}) no-repeat;
  background-size: 100%;
  min-height: 760px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-size: cover;
    margin-top: 100px;
    min-height: 500px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    background-size: cover;
    margin-top: 100px;
    min-height: 500px;
  }
`

const ProductQuoteContainer = styled.div`
  position: relative;
  top: 100px;
  background: ${({ theme }) => theme.colors.white};
  padding: 5%;
  width: 80%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
    top: 509px;
    margin-bottom: 100px;
  }
`

const BannerQuote = styled(ProductQuote)`
  width: 80%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

class QuoteView extends React.Component<QuoteProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <Container>
        <PageContent>
          <ProductQuoteContainer>
            <BannerQuote>
              <QuoteIcon />I know firsthand what it is like to live without
              light and power. When I was a kid we lived in a poor rural house
              and my parents often had no money for kerosene. These experiences
              are the source of my motivation to help improve daily life in
              Congo.
              <QuoteAuthor>Washikala Malongo – Co-Founder & Co-CEO</QuoteAuthor>
            </BannerQuote>
          </ProductQuoteContainer>
        </PageContent>
      </Container>
    )
  }
}

export const Quote = injectIntl(withTheme(QuoteView))
