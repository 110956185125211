import React, { useContext } from 'react'
import { VerticalLayout } from '@altech-rdc/components/lib/layout'
import styled, { ThemeContext, ITheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'

interface PartnersProps {
  title: string
  logo: string
  url: string
  className?: string
  single?: boolean
}

const PartnerComponent: React.FC<PartnersProps & IntlShapeProps> = ({
  title,
  logo,
  url,
  className,
  single
}) => {
  const theme = useContext<ITheme>(ThemeContext)
  let width: string | undefined
  if (single) {
    width = '495px'
  } else {
    width = undefined
  }
  return (
    <Container className={className} width={width}>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content>
        <LogoContainer onClick={() => window.open(url, '_blank')}>
          <Logo src={logo} />
        </LogoContainer>
      </Content>
      <Footer>
        <div>
          <Link href={url} target="_blank">
            {url}
          </Link>
        </div>
      </Footer>
    </Container>
  )
}

const Container = styled(VerticalLayout)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ width }) => (width ? `max-width: ${width}` : 'width: 500px')}
  height: 400px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

const LogoContainer = styled.div`
  display: flex;
  max-width: 70%;
  max-height: 200px;
  margin-bottom: 10px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    max-width: 50%;
  }
`

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  cursor: pointer;
`

const Link = styled.a`
  display: flex;
  ${({ theme }) => theme.fonts.bodyStyle};
  color: ${({ theme }) => theme.colors.black};
  flex-wrap: no-wrap;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.headerStyle};
  }
`

const Header = styled.div`
  display: flex;
  flex-grow: 1;
  height: 90px;
  padding: 30px;
`

const Title = styled.div`
  display: flex;
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.bodyStyle3};
  }
`

const Content = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Footer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 90px;
  padding: 30px;
  padding-left: 47px;
  background-color: ${({ theme }) => theme.colors.primary};
`

const Partner = injectIntl(PartnerComponent)

export { Partner }
