import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { YellowPath } from '@client/components/YellowPath'
import {
  HorizontalLayout,
  VerticalLayout
} from '@altech-rdc/components/lib/layout'

interface WWDHeroProps {
  theme: ITheme
}

const WWDHeroContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  height: 600px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: auto;
  }
`

const WWDHeroTextContainer = styled.div`
  flex-direction: column;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
  }
`

const H1Text = styled.div`
  ${({ theme }) => theme.fonts.heroStyle};
  padding-left: 30px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 30px;
    ${({ theme }) => theme.fonts.h3Style};
  }
`

const H2Text = styled.div`
  ${({ theme }) => theme.fonts.h2Style};
  padding-left: 30px;
`

const H5Text = styled.div`
  ${({ theme }) => theme.fonts.h5Style2};
  width: 50%;
  margin-top: 50px;
  position: relative;
  left: 50%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    left: 0%;
    margin-left: 5%;
  }
`

const AccentHolder = styled.div`
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    margin-left: 5%;
    margin-top: 30px;
  }
`

class WWDHeroView extends React.Component<WWDHeroProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <WWDHeroContainer>
        <VerticalLayout>
          <HorizontalLayout>
            <AccentHolder>
              <YellowPath type="vertical" />
            </AccentHolder>
            <VerticalLayout>
              <WWDHeroTextContainer>
                <H1Text>Solutions</H1Text>
                <H2Text>– of, by, and for the Congolese people</H2Text>
              </WWDHeroTextContainer>
            </VerticalLayout>
          </HorizontalLayout>
          <H5Text>
            At Altech Group, we believe that to achieve universal energy access,
            local people have to own DRC’s energy poverty problem. Their needs
            and the challenges they face in everyday life must be at the heart
            of any solution.
          </H5Text>
        </VerticalLayout>
      </WWDHeroContainer>
    )
  }
}

export const WWDHero = injectIntl(withTheme(WWDHeroView))
