import { LoopReducer, Loop } from 'redux-loop'
import * as actions from '@client/i18n/actions'
import { getDefaultLanguage, getAvailableLanguages } from '@client/i18n/utils'

export interface IntlMessages {
  [key: string]: string
}

export interface ILanguage {
  lang: string
  displayName: string
  messages: IntlMessages
}

export interface ILanguageState {
  [key: string]: ILanguage
}

export type IntlState = {
  language: string
  messages: IntlMessages
  languages: ILanguageState
}

interface ISupportedLanguages {
  code: string
  language: string
}

const supportedLanguages: ISupportedLanguages[] = [
  { code: 'en', language: 'English' },
  { code: 'fr', language: 'Français' }
]

export const initLanguages = () => {
  const initLanguages: ILanguageState = {}
  getAvailableLanguages().forEach(lang => {
    const languageDescription = supportedLanguages.find(
      obj => obj.code === lang
    ) as ISupportedLanguages
    initLanguages[lang] = {
      lang,
      displayName: languageDescription.language,
      messages: {}
    }
  })
  return initLanguages
}

const DEFAULT_MESSAGES = { default: 'default' }

export const initialState: IntlState = {
  language: getDefaultLanguage(),
  messages: DEFAULT_MESSAGES,
  languages: initLanguages()
}

const getNextMessages = (
  language: string,
  languages: ILanguageState
): IntlMessages => {
  return languages[language].messages
}

export const intlReducer: LoopReducer<IntlState, any> = (
  state: IntlState = initialState,
  action: actions.Action
): IntlState | Loop<IntlState, actions.Action> => {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      const messages = getNextMessages(action.payload.language, state.languages)

      return {
        ...state,
        language: action.payload.language,
        messages
      }
    default:
      return state
  }
}
