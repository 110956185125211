import { push, goBack as back } from 'connected-react-router'
import { loop, Cmd } from 'redux-loop'

export interface IDynamicValues {
  [key: string]: any
}

export function formatUrl(url: string, props: { [key: string]: string }) {
  return Object.keys(props).reduce(
    (str, key) => str.replace(`:${key}`, props[key]),
    url
  )
}

export const GO_TO_SECTION = 'navigation/GO_TO_SECTION'
type GoToSectionAction = {
  type: typeof GO_TO_SECTION
  payload: {
    formSectionRoute: string
    draftId: string
    formSectionId: string
    fieldNameHash?: string
    historyState?: IDynamicValues
  }
}

export type Action = GoToSectionAction

export function goBack() {
  return back()
}

export function goToPage(route: string) {
  return push(route)
}

export function goToSection(
  formSectionRoute: string,
  draftId: string,
  formSectionId: string,
  fieldNameHash?: string,
  historyState?: IDynamicValues
) {
  return {
    type: GO_TO_SECTION,
    payload: {
      draftId,
      formSectionId,
      fieldNameHash,
      formSectionRoute,
      historyState
    }
  }
}

export type INavigationState = undefined

export function navigationReducer(state: INavigationState, action: any) {
  switch (action.type) {
    case GO_TO_SECTION:
      const {
        fieldNameHash,
        draftId,
        formSectionId,
        formSectionRoute,
        historyState
      } = action.payload
      return loop(
        state,
        Cmd.action(
          push(
            formatUrl(formSectionRoute, {
              draftId: draftId.toString(),
              formSectionId
            }) + (fieldNameHash ? `#${fieldNameHash}` : ''),
            historyState
          )
        )
      )
  }
}
