import React from 'react'
import styled from 'styled-components'
import {
  HorizontalLayout,
  VerticalLayout
} from '@altech-rdc/components/lib/layout'
import { LinkImage } from './LinkImage'

interface AwardProps {
  title: string
  subTitle: string
  logo: string
  urlText: string
  url: string
}

const Award: React.FC<AwardProps> = ({
  title,
  subTitle,
  logo,
  urlText,
  url
}) => {
  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <Bar />
      <Description>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <LinkSection>
          <SpacedLinkImage />
          <Link href={url}>{urlText}</Link>
        </LinkSection>
      </Description>
    </Container>
  )
}

const SpacedLinkImage = styled(LinkImage)`
  margin-right: 10px;
`

const LinkSection = styled(HorizontalLayout)`
  margin-top: 15px;
  align-items: center;
`

const Link = styled.a`
  display: flex;
  ${({ theme }) => theme.fonts.bodyStyle};
  color: ${({ theme }) => theme.colors.secondary};
  flex-wrap: no-wrap;
  cursor: pointer;
  text-decoration: none;
`

const Title = styled.div`
  ${({ theme }) => theme.fonts.h5Style3}
`

const SubTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style2}
`

const Container = styled(HorizontalLayout)`
  display: flex;
  align-items: center;
  margin-bottom: 100px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
`

const Description = styled(VerticalLayout)`
  display: flex;
  flex-grow: 3;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    align-items: flex-start;
    margin-left: 10px;
  }
`

const Bar = styled.div`
  display: flex;
  margin-left: 70px;
  margin-right: 40px;
  border-left: 6px solid ${({ theme }) => theme.colors.barColor};
  height: 100px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: none;
  }
`

const LogoContainer = styled.div`
  display: flex;
  max-height: 90px;
  flex-basis: 10%;
  flex-grow: 2;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    align-self: center;
    margin-bottom: 20px;
  }
`

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

export { Award }
