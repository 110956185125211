"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.SolarHome = function (props) {
  return React.createElement("svg", Object.assign({
    width: 39,
    height: 39,
    viewBox: "0 0 39 39",
    fill: "none"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0 13L19.5 0L39 13V39H26.2083H12.7917H0V13ZM11.0121 36H27.9879C28.3195 35.0617 28.5 34.0519 28.5 33C28.5 28.0294 24.4706 24 19.5 24C14.5294 24 10.5 28.0294 10.5 33C10.5 34.0519 10.6805 35.0617 11.0121 36ZM21 18C21 18.8284 20.3284 19.5 19.5 19.5C18.6716 19.5 18 18.8284 18 18C18 17.1716 18.6716 16.5 19.5 16.5C20.3284 16.5 21 17.1716 21 18ZM11.0147 20.8934C11.6005 21.4792 11.6005 22.4289 11.0147 23.0147C10.4289 23.6005 9.47919 23.6005 8.8934 23.0147C8.30761 22.4289 8.30761 21.4792 8.8934 20.8934C9.47919 20.3076 10.4289 20.3076 11.0147 20.8934ZM27.9853 23.0147C28.5711 23.6005 29.5208 23.6005 30.1066 23.0147C30.6924 22.4289 30.6924 21.4792 30.1066 20.8934C29.5208 20.3076 28.5711 20.3076 27.9853 20.8934C27.3995 21.4792 27.3995 22.4289 27.9853 23.0147ZM33 33C32.1716 33 31.5 32.3284 31.5 31.5C31.5 30.6716 32.1716 30 33 30C33.8284 30 34.5 30.6716 34.5 31.5C34.5 32.3284 33.8284 33 33 33ZM4.5 31.5C4.5 32.3284 5.17157 33 6 33C6.82843 33 7.5 32.3284 7.5 31.5C7.5 30.6716 6.82843 30 6 30C5.17157 30 4.5 30.6716 4.5 31.5Z",
    fill: props.color || '#A2C0C5'
  }));
};