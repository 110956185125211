"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // TODO: we need a way to load fonts for other languages without recompiling

var languageFonts = {
  fr: {
    boldHeaderFontFamily: 'boing_bold',
    headerFontFamily: 'web_boing_bold',
    boldFontFamily: 'ibm_plex_sans_bold',
    mediumFontFamily: 'ibm_plex_sans_medium',
    regularFontFamily: 'ibm_plex_sans_regular',
    semiBoldFontFamily: 'ibm_plex_sans_semi_bold'
  },
  en: {
    boldHeaderFontFamily: 'boing_bold',
    headerFontFamily: 'web_boing_bold',
    boldFontFamily: 'ibm_plex_sans_bold',
    mediumFontFamily: 'ibm_plex_sans_medium',
    regularFontFamily: 'ibm_plex_sans_regular',
    semiBoldFontFamily: 'ibm_plex_sans_semi_bold'
  },
  default: {
    boldHeaderFontFamily: 'boing_bold',
    headerFontFamily: 'web_boing_bold',
    boldFontFamily: 'ibm_plex_sans_bold',
    mediumFontFamily: 'ibm_plex_sans_medium',
    regularFontFamily: 'ibm_plex_sans_regular',
    semiBoldFontFamily: 'ibm_plex_sans_semi_bold'
  }
};

exports.fonts = function (language) {
  var regularFont = languageFonts[language].regularFontFamily || languageFonts.default.regularFontFamily;
  var semiBoldFont = languageFonts[language].semiBoldFontFamily || languageFonts.default.semiBoldFontFamily;
  var boldFont = languageFonts[language].boldFontFamily || languageFonts.default.boldFontFamily;
  var boldHeaderFont = languageFonts[language].boldHeaderFontFamily || languageFonts.default.boldHeaderFontFamily;
  var headerFont = languageFonts[language].headerFontFamily || languageFonts.default.headerFontFamily;
  var mediumFont = languageFonts[language].mediumFontFamily || languageFonts.default.mediumFontFamily;
  return {
    boldFont: boldFont,
    boldHeaderFont: boldHeaderFont,
    headerFont: headerFont,
    mediumFont: mediumFont,
    regularFont: regularFont,
    semiBoldFont: semiBoldFont,
    h1Style: "font-family: ".concat(boldHeaderFont, ";\n      font-size: 64px;"),
    h2Style: "font-family: ".concat(headerFont, ";\n      font-size: 44px;\n      line-height: 52px;"),
    h2Style2: "font-family: ".concat(headerFont, ";\n        font-size: 29px;\n        line-height: 38px;"),
    h3Style: "font-family: ".concat(boldHeaderFont, ";\n      font-size: 36px;"),
    h3Style2: "font-family: ".concat(headerFont, ";\n      font-size: 36px;\n      line-height: 44px;"),
    h5Style: "font-family: ".concat(boldFont, ";\n      font-size: 23px;\n      line-height: 32px;"),
    h5Style2: "font-family: ".concat(regularFont, ";\n      font-size: 23px;\n      line-height: 32px;"),
    h5Style3: "font-family: ".concat(mediumFont, ";\n      font-size: 23px;\n      line-height: 32px;"),
    smallStyle: "font-family: ".concat(regularFont, ";\n        font-size: 15px;\n        line-height: 23px;\n        font-style: normal;\n        font-weight: normal;"),
    bodyStyle: "font-family: ".concat(regularFont, ";\n      font-size: 18px;\n      line-height: 30px;\n      font-style: normal;\n      font-weight: normal;"),
    bodyStyle2: "font-family: ".concat(mediumFont, ";\n      font-size: 18px;\n      line-height: 30px;"),
    bodyStyle3: "font-family: ".concat(semiBoldFont, ";\n      font-size: 18px;\n      line-height: 30px;"),
    headerStyle: "font-family: ".concat(regularFont, ";\n      font-size: 13px;\n      line-height: 20px;"),
    heroStyle: "font-family: ".concat(boldHeaderFont, ";\n      font-size: 64px;\n      line-height: 76px;")
  };
};