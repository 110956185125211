import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent } from '@altech-rdc/components/lib/layout'
import livingConditions from '@client/images/splash2-image.png'
import { ProductQuote, QuoteIcon } from '@client/components/home/Products'

interface ProductsQuoteProps {
  theme: ITheme
}

const Container = styled.div`
  background: url(${livingConditions}) no-repeat;
  background-size: 100%;
  min-height: 700px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-size: cover;
    min-height: 500px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    background-size: cover;
    min-height: 500px;
    margin-top: -30px;
  }
  @media (max-width: 638px) {
    background-position: -200px 0px;
  }
`

const ProductQuoteContainer = styled.div`
  position: relative;
  top: 380px;
  background: ${({ theme }) => theme.colors.white};
  padding: 5%;
  width: 80%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
    top: 500px;
    margin-bottom: 100px;
  }
`

const BannerQuote = styled(ProductQuote)`
  width: 80%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

class ProductsQuoteView extends React.Component<
  ProductsQuoteProps & IntlShapeProps
> {
  render() {
    const { intl } = this.props
    return (
      <Container>
        <PageContent>
          <ProductQuoteContainer>
            <BannerQuote>
              <QuoteIcon />
              All our products come with two-year warranty and local after-sale
              servce
            </BannerQuote>
          </ProductQuoteContainer>
        </PageContent>
      </Container>
    )
  }
}

export const ProductsQuote = injectIntl(withTheme(ProductsQuoteView))
