import React from 'react'
import styled from '@client/styledComponents'
import { HorizontalLogo } from './HorizontalLogo'
import { YellowPath } from './YellowPath'
import { FacebookIcon } from './footer-icons/FacebookIcon'
import { LinkedInIcon } from './footer-icons/LinkedIn'
import { TwitterIcon } from './footer-icons/TwitterIcon'
import { InstagramIcon } from './footer-icons/InstagramIcon'
import {
  VerticalLayout,
  HorizontalLayout
} from '@altech-rdc/components/lib/layout'

interface FooterProps {}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.footerBackground};
  min-height: 35%;
`

const IconsLayout = styled(HorizontalLayout)`
  max-height: 35%;
  min-width: 240px;
  align-items: flex-start;
  margin-left: -20px;
  padding-left: 0;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const FooterRow = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 0;
`

const FooterColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 50px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 50px;
  }
`

const AddressColumn = styled(FooterColumn)`
  align-items: flex-start;
  padding-left: 15%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    padding-left: 0px;
  }
`
const ContactColumn = styled(FooterColumn)`
  align-items: flex-end;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    align-items: flex-start;
  }
`

const FooterLayout = styled(HorizontalLayout)`
  padding: 5% 10%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    flex-direction: column;
  }
`

const TextBold = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  color: ${({ theme }) => theme.colors.white};
`

const Text = styled.div<{ margin?: string }>`
  ${({ theme }) => theme.fonts.bodyStyle};
  color: ${({ theme }) => theme.colors.white};

  ${({ margin }) => `margin ${margin}`};

  text-align: left;
`

const ContactText = styled(Text)`
  text-align: right;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    text-align: left;
  }
`

const ContactContent = styled(VerticalLayout)`
  position: relative;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    margin-top: 30px;
  }
`

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container>
      <VerticalLayout>
        <FooterRow>
          <YellowPath />
        </FooterRow>
        <FooterLayout>
          <FooterColumn>
            <HorizontalLogo />
          </FooterColumn>
          <AddressColumn>
            <TextBold>Altech Group</TextBold>
            <Text>SARL ​01-9-N18959N</Text>
            <Text margin="20px 0">
              942, Av. Colonel Mondjiba
              <br /> Kintambo Magasin
              <br /> Commune de Ngaliema
              <br /> Kinshasa, DRC
            </Text>
          </AddressColumn>
          <ContactColumn>
            <IconsLayout>
              <FacebookIcon />
              <LinkedInIcon />
              <TwitterIcon />
              <InstagramIcon />
            </IconsLayout>
            <ContactContent>
              <ContactText>+243-822-230-188 </ContactText>
              <ContactText>contact@altech-rdc.com</ContactText>
            </ContactContent>
          </ContactColumn>
        </FooterLayout>
      </VerticalLayout>
    </Container>
  )
}

export { Footer }
