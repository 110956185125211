/*
 * This file is here to provide automatic typing for
 * "theme" context property coming in to all components.
 * This also requires that instead of
 *
 * import styled from '@client/styledComponents';
 *
 * we import it from this module
 *
 * import styled from '@client/styledComponents';
 */

import * as styledComponents from 'styled-components'
import { ITheme as IThemeInterface } from '@altech-rdc/components/lib/theme'

export type ITheme = IThemeInterface

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  ThemeContext,
  withTheme
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ITheme>

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  ThemeContext,
  withTheme
}
export default styled
