"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.Cross = function (props) {
  return React.createElement("svg", {
    width: 18,
    height: 18,
    viewBox: "0 0 18 18",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7.77817 9.19239L0.914214 16.0563L2.32843 17.4706L9.19238 10.6066L16.0563 17.4706L17.4706 16.0564L10.6066 9.19239L17.8848 1.91421L16.4706 0.5L9.19238 7.77818L1.91421 0.500013L0.5 1.91423L7.77817 9.19239Z",
    fill: "white"
  }));
};