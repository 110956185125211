"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.CookStove = function (props) {
  return React.createElement("svg", Object.assign({
    width: 39,
    height: 42,
    viewBox: "0 0 39 42",
    fill: "none"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M36 10V13H34.5V21.2495C35.7535 20.3079 37.3116 19.75 39 19.75V22.75C36.5147 22.75 34.5 24.7647 34.5 27.25V31C34.5 36.799 29.799 41.5 24 41.5H15C9.20101 41.5 4.5 36.799 4.5 31V27.25C4.5 24.7647 2.48528 22.75 0 22.75V19.75C1.68841 19.75 3.24651 20.3079 4.5 21.2495V13H3V10H6H33H36ZM19.1475 26.3322C19.6617 26.5811 22.8372 28.118 25.3092 31.9004C25.7819 33.0039 23.3761 34.3079 23.3761 34.3079C17.3797 36.5576 7.79337 26.0197 14.0265 15.0477C14.421 14.2978 15.4861 14.3373 15.8806 15.0477C16.5907 16.3896 18.2082 18.2841 21.7192 20.139C26.4532 22.586 29.2936 27.2827 27.2422 30.9926C27.1238 31.2295 26.7688 31.2295 26.611 30.9926C23.9963 26.6707 20.0347 24.9708 19.6565 24.8085C19.641 24.8019 19.6315 24.7978 19.6284 24.7962C19.2339 24.6384 18.7605 24.8752 18.6027 25.2698C18.4449 25.6645 18.6816 26.1381 19.0761 26.296C19.0792 26.2991 19.1037 26.311 19.1475 26.3322Z",
    fill: props.color || '#A2C0C5'
  }), React.createElement("path", {
    d: "M13.2247 1C13.2247 1 11.0754 2.875 12.698 4.75C14.3205 6.625 12.6607 8.5 12.6607 8.5",
    stroke: "#A2B5B8",
    strokeWidth: "1.5"
  }), React.createElement("path", {
    d: "M20.7247 1C20.7247 1 18.5754 2.875 20.198 4.75C21.8205 6.625 20.1607 8.5 20.1607 8.5",
    stroke: "#A2B5B8",
    strokeWidth: "1.5"
  }), React.createElement("path", {
    d: "M28.2247 1C28.2247 1 26.0754 2.875 27.698 4.75C29.3205 6.625 27.6607 8.5 27.6607 8.5",
    stroke: "#A2B5B8",
    strokeWidth: "1.5"
  }));
};