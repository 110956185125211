import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { App } from '@client/App'
import { createStore } from '@client/store'

const { store, history } = createStore()

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
)
