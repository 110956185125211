import { defineMessages, MessageDescriptor } from 'react-intl'

interface INewsMessages {
  newsTitle: MessageDescriptor
}

const messagesToDefine = {
  newsTitle: {
    id: 'newspage.newsTitle',
    defaultMessage: 'News',
    description: 'News title'
  }
}

export const messages: INewsMessages = defineMessages(messagesToDefine)
