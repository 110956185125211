"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.ArrowBack = function (props) {
  return React.createElement("svg", Object.assign({
    width: 28,
    height: 27,
    viewBox: "0 0 28 27",
    fill: "none"
  }, props), React.createElement("path", {
    d: "M15.5001 1.47918L3.47924 13.5L15.5001 25.5208",
    stroke: "black",
    strokeWidth: "4"
  }));
};