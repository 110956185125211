"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.Distribution = function (props) {
  return React.createElement("svg", Object.assign({
    width: 41,
    height: 31,
    viewBox: "0 0 41 31",
    fill: "none"
  }, props), React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M34.1373 16C37.3665 13.8305 40.5957 10.103 40.5957 6.5085C40.5957 2.91394 37.7042 0 34.1373 0C30.5704 0 27.6788 2.91394 27.6788 6.5085C27.6788 10.103 30.9081 13.9661 34.1373 16ZM34.281 9.18519C35.8663 9.18519 37.1514 7.85862 37.1514 6.22223C37.1514 4.58584 35.8663 3.25927 34.281 3.25927C32.6957 3.25927 31.4106 4.58584 31.4106 6.22223C31.4106 7.85862 32.6957 9.18519 34.281 9.18519ZM10.3796 30C15.5694 26.5133 20.7593 20.5227 20.7593 14.7457C20.7593 8.96883 16.1121 4.28571 10.3796 4.28571C4.64713 4.28571 0 8.96883 0 14.7457C0 20.5227 5.18982 26.7313 10.3796 30ZM10.6103 19.0476C13.1581 19.0476 15.2235 16.9156 15.2235 14.2857C15.2235 11.6558 13.1581 9.52377 10.6103 9.52377C8.06253 9.52377 5.99715 11.6558 5.99715 14.2857C5.99715 16.9156 8.06253 19.0476 10.6103 19.0476ZM25.239 16.1429C24.8792 16.1429 24.5471 16.3362 24.3694 16.6492C24.1917 16.9622 24.1959 17.3464 24.3804 17.6554L31.1522 29H13.3782C12.8259 29 12.3782 29.4477 12.3782 30C12.3782 30.5523 12.8259 31 13.3782 31H32.9137C33.2736 31 33.6057 30.8067 33.7833 30.4937C33.961 30.1807 33.9568 29.7965 33.7723 29.4875L27.0006 18.1429H34.1375C34.6898 18.1429 35.1375 17.6952 35.1375 17.1429C35.1375 16.5906 34.6898 16.1429 34.1375 16.1429H25.239Z",
    fill: "#1BACCD"
  }));
};