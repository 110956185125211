import styled from '@client/styledComponents'

const StackContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export { StackContainer }
