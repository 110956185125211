import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { PageContent } from '@altech-rdc/components/lib/layout'
import processImage from '@client/images/process.png'
import livingConditions from '@client/images/living-conditions.png'

interface WWDBannerProps {
  theme: ITheme
}

const Container = styled.div`
  background: url(${processImage}) no-repeat;
  background-size: 100%;
  min-height: 630px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    background-size: cover;
    min-height: 500px;
  }
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    background-size: cover;
    min-height: 500px;
  }
`

const BannerTitleContainer = styled.div`
  width: 100%;
`

const BannerTitle = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 5%;
  position: relative;
  width: 50%;
  left: 40%;
  top: 100px;
  ${({ theme }) => theme.fonts.h3Style2};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.md}px) {
    ${({ theme }) => theme.fonts.h5Style3};
  }
`

class WWDBannerView extends React.Component<WWDBannerProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <Container>
        <PageContent>
          <PageContent>
            <BannerTitleContainer>
              <BannerTitle>
                We put the needs of the communities we serve first and above
                everything else.
              </BannerTitle>
            </BannerTitleContainer>
          </PageContent>
        </PageContent>
      </Container>
    )
  }
}

export const WWDBanner = injectIntl(withTheme(WWDBannerView))
