import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { VerticalLayout, PageContent } from '@altech-rdc/components/lib/layout'
import washikala from '@client/images/washikala.png'
import iongwa from '@client/images/iongwa.png'
import { YellowPath } from '@client/components/YellowPath'

interface LeadershipProps {
  theme: ITheme
}

const LeadershipRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`

const LeadershipCopy = styled(VerticalLayout)`
  padding: 60px 75px 0px 75px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    padding-left: 30px;
  }
`

const LeadershipColumn = styled.div`
  width: 400px;
  flex-basis: 100%;
  -ms-flex: auto;
  position: relative;
  padding: 0px 0px 40px 0px;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    padding: 0;
  }
`

const LeadershipTitle = styled.div`
  ${({ theme }) => theme.fonts.h5Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
  margin-bottom: 20px;
`

const LeadershipDescription = styled.div`
  margin-top: -15px;
  color: ${({ theme }) => theme.colors.darkCopy};
`

const LeadershipPageTitle = styled.div`
  ${({ theme }) => theme.fonts.h3Style};
  color: ${({ theme }) => theme.colors.darkestCopy};
`

const LeadershipItems = styled(PageContent)`
  width: 100%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 500px;
  }
`

const LeadershipImage = styled.img`
  max-width: 100%;
  height: auto;
  position: relative;
  left: 70px;
  top: 40px;
`
const Accent = styled(YellowPath)`
  margin-top: 30px;
`

const LeadershipComment = styled.div`
  width: 50%;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 75%;
  }
`

class LeadershipView extends React.Component<LeadershipProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <LeadershipItems>
        <LeadershipRow>
          <LeadershipColumn>
            <LeadershipCopy>
              <LeadershipPageTitle>Leadership Team</LeadershipPageTitle>
            </LeadershipCopy>
          </LeadershipColumn>
        </LeadershipRow>
        <LeadershipRow>
          <LeadershipColumn>
            <LeadershipImage src={washikala} />
            <LeadershipCopy>
              <LeadershipTitle>Washikala Malango</LeadershipTitle>
              <LeadershipDescription>
                Co-Founder and Co-CEO
              </LeadershipDescription>
              <Accent type="accent" />
            </LeadershipCopy>
            <LeadershipComment>
              Washikala is a social entrepreneur and development specialist. As
              co-founder and co-director of the Altech Group, he designs and
              implements business models which close the clean energy
              distribution gap in the DRC, while addressing energy needs of the
              country’s off-grid and under-electrified populations and
              institutions.
              <br />
              <br />
              He has also co-founded and been involved in initiatives which
              promote inclusive development build leadership and promote
              entrepreneurship among youth, including YEH, I4RG, IED, CLI, and
              YALI DRC.
              <br />
              <br />
              Washikala holds a Master’s degree in Development Studies from the
              University of Dar es Salaam and a Business &amp; Entrepreneurship
              certificate from Dartmouth College.
            </LeadershipComment>
          </LeadershipColumn>
        </LeadershipRow>
        <LeadershipRow>
          <LeadershipColumn>
            <LeadershipImage src={iongwa} />
            <LeadershipCopy>
              <LeadershipTitle>Iongwa Mashangao</LeadershipTitle>
              <LeadershipDescription>
                Co-Founder and Co-CEO
              </LeadershipDescription>
              <Accent type="accent" />
            </LeadershipCopy>
            <LeadershipComment>
              Iongwa Mashangao is a social entrepreneurial leader and
              development specialist. He has over seven years of experience
              developing and managing business models for the Base of Pyramid
              Customers in East and Central Africa.
              <br />
              <br />
              In 2007, he co-founded IED, an innovation initiative that provides
              business-development services to promising young people in the
              DRC. In 2013, he co-founded Altech Group, as well as the Maize
              Production and Processing Line Company. Iongwa is a 2014
              Westerwelle Foundation Fellow, a 2015 IVLP US Exchange Program
              Fellow, a YALI Regional Center East Africa alumnus, and 6th Global
              Entrepreneurship Summit DRC 2015 delegate.
              <br />
              <br />
              He holds a BA in Education and an MA in Development Studies from
              the University of Dar es Salaam, Tanzania, a Business,
              Entrepreneurship &amp; Renewable Energy certificate from the
              University of California, Davis and a Business Entrepreneurship
              certificate from Kenyatta University, Kenya.
            </LeadershipComment>
          </LeadershipColumn>
        </LeadershipRow>
      </LeadershipItems>
    )
  }
}

export const Leadership = injectIntl(withTheme(LeadershipView))
