import React from 'react'
import missionImage from '@client/images/mission.png'
import backSlash from '@client/images/background-backslash.svg'
import forwardSlash from '@client/images/background-forwardslash.svg'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import {
  PageContent,
  HorizontalLayout,
  VerticalLayout
} from '@altech-rdc/components/lib/layout'

interface MissionProps {
  theme: ITheme
}

const MissionContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: flex-end;
  background: linear-gradient(
    359.43deg,
    rgba(0, 0, 0, 0.5) 0.85%,
    rgba(0, 0, 0, 0) 99.02%
  );
  padding-right: 0px;
`

const SplashImage = styled.div`
  background: url(${missionImage}) no-repeat center center scroll;
  width: 100%;
  height: 700px;
  background-size: cover;

  position: relative;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 400px;
    width: 100%;
    background-size: cover;
    padding-right: 0px;
  }
`

const PathContainer = styled.div`
  position: absolute;
  top: 2700px;
  padding-right: 0;
  margin-right: 0px;
  width: 100%;
  z-index: 20;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    top: 932px;
  }
`

const TextContainer = styled.div`
  position: relative;
  width: 861px;
  margin-top: 60px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 0px;
    width: 100%;
  }
`

const MissionTextContainer = styled.div`
  position: relative;
  width: 861px;
  background: ${({ theme }) => theme.colors.white};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    margin-top: 0px;
    width: 100%;
  }
`

const HeroText = styled.div`
  padding: 5%;
  ${({ theme }) => theme.fonts.h2Style};
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.h3Style2};
  }
`

const PageDescription = styled.div`
  width: 594px;
  margin: auto;
  position: relative;
  margin-top: 20px;
  margin-bottom: 180px;
  color: ${({ theme }) => theme.colors.darkestCopy};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin: 0 auto;
  }
`

const Wrapper = styled.div`
  height: 1350px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 1200px;
    top: 440px;
    position: relative;
  }
`

const MissionStatementContainer = styled.div`
  position: absolute;
  top: 200px;
  width: 50%;
  left: 50%;
  z-index: 20;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    position: relative;
    top: -20px;
    width: 100%;
    left: 0%;
  }
`

const MissionStatement = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin-left: 5%;
  }
`

const MissionStatementTitle = styled.div`
  height: 200px;
  background-image: url(${backSlash});
  width: 178px;
  padding-left: 20px;
  padding-top: 40px;
  ${({ theme }) => theme.fonts.h5Style};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 180px;
  }
`

const MissionStatementTitle2 = styled.div`
  height: 200px;
  background-image: url(${forwardSlash});
  width: 163px;
  padding-left: 20px;
  padding-top: 40px;
  ${({ theme }) => theme.fonts.h5Style};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 180px;
  }
`

const MissionStatementDescription = styled.div`
  width: 300px;
  padding-left: 40px;
  padding-top: 40px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 290px;
    padding-right: 10px;
    padding-left: 19px;
    padding-top: 16px;
  }
`

class MissionView extends React.Component<MissionProps & IntlShapeProps> {
  render() {
    const { intl } = this.props
    return (
      <Wrapper>
        <PageContent>
          <TextContainer>
            <HeroText>
              90% of Congolese people lack access to electricity. Altech was
              founded to address this energy deficit.
            </HeroText>
          </TextContainer>
          <PageDescription>
            People are forced to turn to kerosene lamps, candles and diesel or
            petrol generators, spending over $1bn a year on lighting alone.
            These dirty, expensive and unsafe sources of energy reduce people’s
            incomes, worsen their health, disrupt their work and learning, and
            threaten the environment.
          </PageDescription>
        </PageContent>
        <MissionContainer>
          <SplashImage>
            <PageContent>
              <MissionStatementContainer>
                <VerticalLayout>
                  <MissionStatement>
                    <HorizontalLayout>
                      <MissionStatementTitle>
                        End
                        <br />
                        Energy Poverty
                      </MissionStatementTitle>
                      <MissionStatementDescription>
                        We are ambitious for the future of DRC. We want to see
                        an end to energy poverty in the DRC by 2030.
                      </MissionStatementDescription>
                    </HorizontalLayout>
                  </MissionStatement>
                  <MissionStatement>
                    <HorizontalLayout>
                      <MissionStatementTitle2>
                        Sell
                        <br />1 Million Products
                      </MissionStatementTitle2>
                      <MissionStatementDescription>
                        In pursuit of this, we aim to sell 1 million products,
                        impact 5 million people &amp; create 2,000 new jobs by
                        2022.
                      </MissionStatementDescription>
                    </HorizontalLayout>
                  </MissionStatement>
                </VerticalLayout>
              </MissionStatementContainer>
            </PageContent>
          </SplashImage>
          <PathContainer>
            <PageContent>
              <MissionTextContainer>
                <HeroText>
                  Our mission is to expand access to clean, reliable, renewable
                  and affordable energy for off- and poor-grid households.
                </HeroText>
              </MissionTextContainer>
            </PageContent>
          </PathContainer>
        </MissionContainer>
      </Wrapper>
    )
  }
}

export const Mission = injectIntl(withTheme(MissionView))
