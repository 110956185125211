"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.PhoneIcon = function (props) {
  return React.createElement("svg", Object.assign({
    width: 30,
    height: 30,
    viewBox: "0 0 30 30",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), React.createElement("path", {
    d: "M4.72995 0.0273278C5.55979 0.152139 6.47261 0.90101 7.012 1.31705C8.0078 2.02432 8.87913 2.898 9.75046 3.73008C10.2484 4.22932 10.7048 4.68696 11.0782 5.26942C12.074 6.85037 11.4101 7.89047 10.5388 9.22179C10.1239 9.84585 8.67167 11.4268 8.67167 11.4268C8.5057 11.6348 10.2069 14.1727 10.4143 14.4639C11.1197 15.4624 11.9495 16.4193 12.8623 17.2929C13.7752 18.1666 14.8124 18.9571 15.8912 19.6644C16.1817 19.8724 18.8371 21.6198 19.0446 21.4117L20.2894 20.1636C20.9947 19.4564 21.7001 18.2914 22.8204 18.2498C24.0651 18.2082 26.0567 20.1636 27.011 20.8709C28.2558 21.8278 29.874 23.0759 29.9984 24.8233C30.0399 25.8634 29.2516 26.9035 28.5877 27.6107C23.3597 33.3521 14.522 27.4027 9.91642 23.7832C4.72995 19.706 -0.912924 13.507 0.12437 6.35112C0.456304 4.14611 1.86702 0.942614 4.10758 0.0689317C4.27354 -0.0142762 4.5225 -0.0142762 4.72995 0.0273278Z",
    fill: "#A1B5B8"
  }));
};