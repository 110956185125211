import React from 'react'
import styled from 'styled-components'
import splashImage from '@client/images/splash-image.png'
import { YellowPath } from '@client/components/YellowPath'
const SplashImage = styled.div`
  background: url(${splashImage}) no-repeat center center fixed;
  width: 100%;
  height: 700px;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 400px;
    background: url(${splashImage}) no-repeat;
    width: 100%;
    background-size: cover;
    padding-right: 0px;
    background-position: 0px 0px;
  }

  @media (max-width: 658px) {
    background-position: -96px 0px;
  }
`

const PathContainer = styled.div`
  position: absolute;
  height: 700px;
`

const InternalPathContainer = styled.div`
  height: 100%;
`

const InverseYellowPath = styled(YellowPath)`
  position: absolute;
  bottom: 700px;
`

const HeroImage: React.FC<{ id: string }> = () => {
  return (
    <>
      <SplashImage id="id" />
      <PathContainer>
        <InternalPathContainer>
          <InverseYellowPath type="inverted" />
        </InternalPathContainer>
      </PathContainer>
    </>
  )
}

export { HeroImage }
