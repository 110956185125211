import React from 'react'

interface LinkImageProps {
  className?: string
}

const LinkImage: React.FC<LinkImageProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3676 3.63382C12.8336 4.09964 12.8336 4.85875 12.3676 5.32457C11.9327 5.75933 11.2422 5.78831 10.7732 5.41152L10.6763 5.32457L8.84695 3.4958C7.36275 2.01208 4.96387 2.02933 3.49693 3.4958C2.07731 4.91496 2.03152 7.22277 3.35955 8.69927L3.49693 8.84408L5.32629 10.6728C5.79226 11.1387 5.79226 11.8978 5.32629 12.3636C4.89139 12.7984 4.20091 12.8273 3.73193 12.4505L3.635 12.3636L1.80564 10.5348C-0.593242 8.13673 -0.6105 4.2204 1.80564 1.80505C4.16686 -0.555408 7.96207 -0.592577 10.3724 1.64523L10.5382 1.80505L12.3676 3.63382ZM18.2008 9.46517C20.5997 11.8633 20.5997 15.7968 18.2008 18.195C15.8565 20.5385 12.0617 20.6083 9.63527 18.3559L9.46824 18.195L7.63888 16.3662C7.17291 15.9004 7.17291 15.1413 7.63888 14.6754C8.07378 14.2407 8.76426 14.2117 9.23324 14.5885L9.33017 14.6754L11.1595 16.5042C12.6437 17.9879 15.0426 17.9707 16.5095 16.5042C17.9292 15.085 17.975 12.7772 16.6469 11.3007L16.5095 11.1559L14.6802 9.32715C14.2142 8.86133 14.2142 8.10222 14.6802 7.6364C15.1151 7.20164 15.8056 7.17266 16.2745 7.54945L16.3715 7.6364L18.2008 9.46517ZM14.5594 14.5547C15.0253 14.0888 15.0253 13.3297 14.5594 12.8639L7.13839 5.44533L7.04146 5.35838C6.57248 4.98159 5.882 5.01057 5.4471 5.44533C4.98113 5.91115 4.98113 6.67026 5.4471 7.13608L12.8681 14.5547L12.965 14.6416C13.434 15.0184 14.1245 14.9894 14.5594 14.5547Z"
        fill="#00ACCF"
      />
    </svg>
  )
}

export { LinkImage }
