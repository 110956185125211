import React from 'react'

const InverseYellowPath: React.FC = () => {
  return (
    <svg
      width="1440"
      viewBox="0 0 1440 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1440 0V70H0L1440 0Z" fill="#FEEB34" />
    </svg>
  )
}

export { InverseYellowPath }
