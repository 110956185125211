import React from 'react'
import styled from '@client/styledComponents'
import { StackContainer } from '@client/components/footer-icons/StackContainer'
import { StackItemContainer } from '@client/components/footer-icons/StackItemContiner'
import { IconBackdrop } from './IconBackdrop'
import { ArrowForward } from '@altech-rdc/components/lib/icons'

const IconContainer = styled(StackContainer)`
  margin-top: 25px;
  cursor: pointer;
`

const ArrowForwardButton: React.FC = () => {
  return (
    <IconContainer>
      <StackItemContainer>
        <IconBackdrop />
      </StackItemContainer>
      <StackItemContainer>
        <ArrowForward />
      </StackItemContainer>
    </IconContainer>
  )
}

export { ArrowForwardButton }
