import * as React from 'react'
import styled from '@client/styledComponents'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'

const languageFromProps = ({ language }: IPageProps) => language

const StyledPage = styled.div<IPageProps>`
  background: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.semiBoldFont};
    src: url('/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.regularFont};
    src: url('/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.boldFont};
    src: url('/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.mediumFont};
    src: url('/fonts/IBMPlexSans-Medium.ttf') format('truetype');
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.headerFont};
    src: url('/fonts/Boing_RegularWEB.ttf') format('truetype');
  }

  @font-face {
    /* stylelint-disable-next-line altech-rdc/no-font-styles */
    font-family: ${({ theme }) => theme.fonts.boldHeaderFont};
    src: url('/fonts/Boing-Bold.ttf') format('truetype');
  }
`

interface IPageProps {
  language?: string
}

class Component extends React.Component<RouteComponentProps<{}> & IPageProps> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }
  render() {
    const { children } = this.props

    return (
      <div id="readyApplication">
        <StyledPage {...this.props}>{children}</StyledPage>
      </div>
    )
  }
}

const mapStateToProps = (store: IStoreState): IPageProps => {
  return {
    language: getLanguage(store)
  }
}
export const Page = withRouter(
  connect(
    mapStateToProps,
    null
  )(Component)
)
