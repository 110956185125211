import { messages } from '@client/i18n/messages/views/home'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'
import { PageContent, Content } from '@altech-rdc/components/lib/layout'
import * as React from 'react'
import { AboutUsHero } from '@client/components/aboutus/AboutUsHero'
import { Mission } from '@client/components/aboutus/Mission'
import { Quote } from '@client/components/aboutus/Quote'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { connect } from 'react-redux'
import { Leadership } from '@client/components/aboutus/Leadership'

interface IBaseAboutUsProps {
  theme: ITheme
  language: string
}

type AboutUsProps = IBaseAboutUsProps & IntlShapeProps

interface IAboutUsState {
  width: number
}

class AboutUsView extends React.Component<AboutUsProps, IAboutUsState> {
  constructor(props: AboutUsProps) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recordWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recordWindowWidth)
  }

  recordWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Content>
          <AboutUsHero />
          <Quote />
          <Mission />
          <Leadership />
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    language: getLanguage(state)
  }
}

export const AboutUs = connect(
  mapStateToProps,
  null
)(injectIntl(withTheme(AboutUsView)))
