import React from 'react'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { HorizontalLogo } from './HorizontalLogo'
import {
  VerticalLayout,
  HorizontalLayout
} from '@altech-rdc/components/lib/layout'
import { MenuItem } from '@altech-rdc/components/lib/buttons'
import { connect } from 'react-redux'
import {
  PRODUCTS,
  HOME,
  ABOUT_US,
  WHAT_WE_DO,
  CONTACT_US,
  PARTNERS
} from '@client/navigation/routes'
import * as navigationActions from '@client/navigation'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { AppHeader, ExpandingMenu } from '@altech-rdc/components/lib/interface'
import { Hamburger } from '@altech-rdc/components/lib/icons'

const HeaderContainer = styled.section`
  display: flex;
  max-width: 1021px;
  margin: 0 auto 64px auto;
`

const MenuContainer = styled(HorizontalLayout)`
  position: relative;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding-right: 125.5px;
  top: -20px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    display: block;
  }
`

const LogoContainer = styled.div`
  position: relative;
  width: 244px;
  height: 90px;
  top: -5px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    left: 20px;
  }
  cursor: pointer;
`

const Wrapper = styled.div`
  padding: 15px 0;
  max-height: 81px;
  background: ${({ theme }) => theme.colors.headerBackground};
`

interface IBaseHeaderProps {
  theme: ITheme
}

interface IDispatchProps {
  goToPage: (route: string) => void
}

type HeaderProps = IBaseHeaderProps & IntlShapeProps & IDispatchProps

interface IHeaderState {}

interface IState {
  showMenu: boolean
}
class MobileHeaderView extends React.Component<HeaderProps, IState> {
  constructor(props: HeaderProps) {
    super(props)

    this.state = {
      showMenu: false
    }
  }

  hamburger = () => {
    const menuItems = [
      {
        label: 'Products',
        onClick: () => {
          this.setState({
            showMenu: false
          })
          this.props.goToPage(PRODUCTS)
        }
      },
      {
        label: 'About Us',
        onClick: () => {
          this.setState({
            showMenu: false
          })
          this.props.goToPage(ABOUT_US)
        }
      },
      {
        label: 'What We Do',
        onClick: () => {
          this.setState({
            showMenu: false
          })
          this.props.goToPage(WHAT_WE_DO)
        }
      },
      {
        label: 'Partners',
        onClick: () => {
          this.setState({
            showMenu: false
          })
          this.props.goToPage(PARTNERS)
        }
      },
      {
        label: 'Contact Us',
        onClick: () => {
          this.setState({
            showMenu: false
          })
          this.props.goToPage(CONTACT_US)
        }
      }
    ]

    return (
      <>
        {!this.state.showMenu && <Hamburger />}
        <ExpandingMenu
          menuItems={menuItems}
          showMenu={this.state.showMenu}
          menuCollapse={() => false}
        />
      </>
    )
  }

  toggleMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }))
  }

  render() {
    const mobileHeaderActionProps = {
      mobileLeft: {
        icon: () => this.hamburger(),
        handler: this.toggleMenu
      }
    }

    return (
      <>
        <AppHeader id="app_header" {...mobileHeaderActionProps} />
      </>
    )
  }
}

const mapDispatchToProps = {
  goToPage: navigationActions.goToPage
}

export const MobileHeader = connect(
  null,
  mapDispatchToProps
)(injectIntl(withTheme(MobileHeaderView)))

class HeaderView extends React.Component<HeaderProps, IHeaderState> {
  render() {
    return (
      <Wrapper>
        <HeaderContainer>
          <VerticalLayout>
            <LogoContainer onClick={() => this.props.goToPage(HOME)}>
              <HorizontalLogo />
            </LogoContainer>
          </VerticalLayout>
          <MenuContainer>
            <MenuItem onClick={() => this.props.goToPage(PRODUCTS)}>
              Products
            </MenuItem>
            <MenuItem onClick={() => this.props.goToPage(ABOUT_US)}>
              About us
            </MenuItem>
            <MenuItem onClick={() => this.props.goToPage(WHAT_WE_DO)}>
              What we do
            </MenuItem>
            <MenuItem onClick={() => this.props.goToPage(PARTNERS)}>
              Partners
            </MenuItem>
            <MenuItem onClick={() => this.props.goToPage(CONTACT_US)}>
              Contact
            </MenuItem>
          </MenuContainer>
          <MobileMenuContainer>
            <MobileHeader />
          </MobileMenuContainer>
        </HeaderContainer>
      </Wrapper>
    )
  }
}

export const Header = connect(
  null,
  mapDispatchToProps
)(injectIntl(withTheme(HeaderView)))
