import React from 'react'

type YellowPathStyle = 'regular' | 'inverted' | 'accent' | 'vertical'

interface YellowPathProps {
  className?: string
  width?: string
  type?: YellowPathStyle
}

const YellowPath: React.FC<YellowPathProps> = ({
  className,
  width,
  type = 'regular'
}) => {
  switch (type) {
    case 'regular': {
      return (
        <svg
          className={className}
          width={width || '140vw'}
          viewBox="0 0 1440 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 70V0L1440 0L0 70Z" fill="#FAEB00" />
        </svg>
      )
    }
    case 'inverted': {
      return (
        <svg
          className={className}
          width={width || '100vw'}
          viewBox="0 0 1440 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1440 0V70H0L1440 0Z" fill="#FEEB34" />
        </svg>
      )
    }

    case 'accent': {
      return (
        <svg
          className={className}
          width={width || '140'}
          height="42"
          viewBox="0 0 140 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.871668 2.2621L0.0751953 41.6098L137.74 7.75803L139.187 1.34859L4.10111 0.807861L0.871668 2.2621Z"
            fill="#FEEB34"
          />
        </svg>
      )
    }
    case 'vertical': {
      return (
        <svg
          width={width || '58'}
          height="196"
          viewBox="0 0 58 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M52.6878 196L57.7538 190.004L58 4.58899L48.7822 0L0 15.4951L52.6878 196Z"
            fill="#FDEB19"
          />
        </svg>
      )
    }
  }
}

export { YellowPath }
