import { messages } from '@client/i18n/messages/views/home'
import styled, { ITheme, withTheme } from '@client/styledComponents'
import { getLanguage } from '@altech-rdc/client/src/i18n/selectors'
import { IStoreState } from '@altech-rdc/client/src/store'
import { PageContent, Content } from '@altech-rdc/components/lib/layout'
import * as React from 'react'
import { injectIntl, WrappedComponentProps as IntlShapeProps } from 'react-intl'
import { connect } from 'react-redux'
import { WWDBanner } from '@client/components/wwd/WWDBanner'
import { WWDHero } from '@client/components/wwd/WWDHero'
import { Process } from '@client/components/wwd/Process'
import processSplash from '@client/images/process-splash.png'

interface IBaseWhatWeDoProps {
  theme: ITheme
  language: string
}

const SplashImage = styled.div`
  background: url(${processSplash}) no-repeat center center scroll;
  width: 100%;
  height: 700px;
  background-size: cover;

  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    height: 400px;
    width: 100%;
    background-size: cover;
    padding-right: 0px;
  }
`

const TextContainer = styled.div`
  position: relative;
  top: -80px;
  width: 60%;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 100%;
  }
`

const HeroText = styled.div`
  ${({ theme }) => theme.fonts.h2Style};
  color: ${({ theme }) => theme.colors.black};
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    ${({ theme }) => theme.fonts.h3Style2};
    margin-top: 100px;
    width: 90%;
    margin-left: 5%;
  }
`

const ProcessText = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
  @media (max-width: ${({ theme }) => theme.grid.breakpoints.lg}px) {
    width: 90%;
    margin-left: 5%;
  }
`

type WhatWeDoProps = IBaseWhatWeDoProps & IntlShapeProps

interface IWhatWeDoState {
  width: number
}

class WhatWeDoView extends React.Component<WhatWeDoProps, IWhatWeDoState> {
  constructor(props: WhatWeDoProps) {
    super(props)
    this.state = {
      width: window.innerWidth
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recordWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recordWindowWidth)
  }

  recordWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Content>
          <PageContent>
            <WWDHero />
          </PageContent>
          <WWDBanner />
          <PageContent>
            <Process />
          </PageContent>
          <PageContent>
            <TextContainer>
              <HeroText>
                Our sales strategy is based on the recruitment of talented,
                trustworthy and ambitious young people as Solar Ambassadors
              </HeroText>
            </TextContainer>
          </PageContent>
          <SplashImage />
          <PageContent>
            <ProcessText>
              We equip them with the necessary skills and tools to promote,
              sell, collect payment and service our products. Solar Ambassadors
              form the base of Altech’s national distribution network and
              through constant interaction with our customers are the strength
              of the company. Ambassadors are supervised by area sales managers
              and receive a sales commission. We are proud to have created over
              300 new jobs across the DRC.
            </ProcessText>
          </PageContent>
        </Content>
      </>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    language: getLanguage(state)
  }
}

export const WhatWeDo = connect(
  mapStateToProps,
  null
)(injectIntl(withTheme(WhatWeDoView)))
