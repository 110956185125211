"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.LinkArrow = function (props) {
  return React.createElement("svg", Object.assign({
    width: 14,
    height: 15,
    viewBox: "0 0 14 15",
    fill: "none"
  }, props), React.createElement("path", {
    d: "M6.2929 1.2219L12.364 7.29297L6.2929 13.364",
    stroke: props.color,
    strokeWidth: "2"
  }));
};